import React from 'react';

import '../Content.scss';

import { inject, observer } from 'mobx-react';

import Content from '../Content';
import Process from './Process/Process';

function Processes({
  processes,
  processErrMessage,
}) {
  return (
    <Content
      titleSection="Начать процесс"
    >
      <div className="content__wrap-processes">

        {processes.length === 0
          ? (
            <span className="content__err-message">
              {processErrMessage}
            </span>
          ) : (
            processes.map((process) => (
              <ul className="content__processes">
                <Process
                  process={process}
                  key={process.deploymentId}
                />
              </ul>
            ))
          )}

      </div>
    </Content>
  );
}

export default inject(({ ProcessStore }) => {
  const {
    processes,
    processErrMessage,
  } = ProcessStore;

  return {
    processes,
    processErrMessage,
  };
})(observer(Processes));
