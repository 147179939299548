import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line no-unused-vars
import { toJS } from 'mobx';
import CryptoJS from 'crypto-js';

import Content from '../Content';
import FormSearchEmployee from './FormSearchEmployee/FormSearchEmployee';
import Form from '../../../../utils/ui/Form/Form';
import FormField from '../../../../utils/ui/FormField/FormField';
import FormSelect from '../../../../utils/ui/FormSelect/FormSelect';
import FormSelectOption from '../../../../utils/ui/FormSelect/FormSelectOption/FormSelectOption';

import * as validation from './validation';

// eslint-disable-next-line no-unused-vars
import { path, reasonsDismissal, secret } from '../../../../utils/constants/constants';

const {
  validationsSchema,
} = validation;

function FormInitiation({
  userFirstName,
  userLastName,

  process,

  employeeTN,
  employeeFullName,
  employeePosition,
  employeePhone,
  employeeObject,
  employeeStatusOnTheObject,
  employeeBlackList,
  employeeGrayList,

  employeeManagerFullName,
  employeeManagerPhone,

  initiatorDismissalFullName,
  initiatorDismissalPhone,

  projectManagerFullName,
  projectManagerPhone,
  projectManagerEmail,
  projectManagerID,

  handleResetState,

  task,
  handleGetTask,
  handleCompleteTask,

  loading,
  errload,
}) {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      // ! переменные этого таска
      p001_processName: 'Увольнение сотрудника',
      p001_employee_fullName: '',
      p001_employee_position: '',
      p001_employee_TN: '',
      p001_employee_phone: '',
      p001_employee_object: '',
      p001_employee_statusOnTheObject: '',
      p001_reasonDismissal: '',
      p001_dateDismissal: '',
      p001_employeeManager_fullName: '',
      p001_employeeManager_phone: '',
      p001_initiatorDismissal_fullName: '',
      p001_initiatorDismissal_phone: '',
      p001_projectManager_fullName: '',
      p001_projectManager_phone: '',
      p001_projectManager_email: '',
      p001_projectManager_id: '',
      p001_processInitiator: `${userLastName} ${userFirstName}`,
      p001_eventType: 'targetSendEmail',

      // ? переменные из других тасков
      p001_blackListDecision: false,
      p001_grayListDecision: false,
      p001_RPComment: '',
      p001_approveCheck: false,

      p001_initiatorDismissal_comment: '',
      p001_employeeManager_comment: '',

      p001_employee_comment: '',
      p001_112_comment: '',

      p001_dismissalDecision: 'Уволить',
      p001_proceedDecision: '',
    },
    validationSchema: validationsSchema,
    onSubmit: async (values) => {
      const getToken = localStorage.getItem('token');
      const bytesToken = CryptoJS.AES.decrypt(getToken, secret);
      const token = JSON.parse(bytesToken.toString(CryptoJS.enc.Utf8));

      await handleCompleteTask(token, values, task)
        .then(() => {
          handleResetState();
          navigate(path.main);
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err.message)); // вывести ошибку в форме
    },
  });

  useEffect(() => {
    handleResetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // если нет id задачи
    if (!task.id) {
      const getToken = localStorage.getItem('token');
      const bytesToken = CryptoJS.AES.decrypt(getToken, secret);
      const token = JSON.parse(bytesToken.toString(CryptoJS.enc.Utf8));

      handleGetTask(token, process.id); // запросить задачу по id процесса
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  useEffect(() => {
    if (employeeFullName) {
      formik.values.p001_employee_fullName = employeeFullName;
      formik.values.p001_employee_position = employeePosition;
      formik.values.p001_employee_object = employeeObject;
      formik.values.p001_employee_statusOnTheObject = employeeStatusOnTheObject;
      formik.values.p001_employee_phone = employeePhone;
      formik.values.p001_blackListDecision = employeeBlackList;
      formik.values.p001_grayListDecision = employeeGrayList;

      formik.setValues(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    employeeFullName, employeePosition, employeePhone,
    employeeObject, employeeStatusOnTheObject,
    employeeBlackList, employeeGrayList,
  ]);

  useEffect(() => {
    if (employeeManagerFullName) {
      formik.values.p001_employeeManager_fullName = employeeManagerFullName;
      formik.values.p001_employeeManager_phone = employeeManagerPhone;

      formik.setValues(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeManagerFullName, employeeManagerPhone]);

  useEffect(() => {
    if (initiatorDismissalFullName) {
      formik.values.p001_initiatorDismissal_fullName = initiatorDismissalFullName;
      formik.values.p001_initiatorDismissal_phone = initiatorDismissalPhone;

      formik.setValues(formik.values);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiatorDismissalFullName, initiatorDismissalPhone]);

  useEffect(() => {
    if (employeeTN) {
      formik.values.p001_employee_TN = employeeTN;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeTN]);

  useEffect(() => {
    if (projectManagerFullName) {
      formik.values.p001_projectManager_fullName = projectManagerFullName;
      formik.values.p001_projectManager_phone = projectManagerPhone;
      formik.values.p001_projectManager_email = projectManagerEmail;
      formik.values.p001_projectManager_id = projectManagerID;

      formik.setValues(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectManagerFullName, projectManagerPhone,
    projectManagerEmail, projectManagerID,
  ]);

  return (
    <Content
      titleSection="Заполнить форму инициации"
    >

      <FormSearchEmployee
        searchItem="Cотрудник"
        fieldLabel="Поиск сотрудника по ТН"
      />

      <Form
        buttonText={loading ? 'Сохранение...' : 'Отправить'}
        onSubmit={formik.handleSubmit}
        disabled={!formik.isValid}
        loading={loading}
        error={errload}
      >

        <FormField
          fieldType="text"
          fieldLabel="Табельный номер увольняемого сотрудника"
          fieldName="p001_employee_TN"
          fieldID="FormInitiation_p001_employee_TN"
          fieldPlaceholder="Табельный номер"
          value={formik.values.p001_employee_TN}
          error={formik.errors.p001_employee_TN}
          touched={formik.touched.p001_employee_TN}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_employee_TN');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormField
          fieldType="text"
          fieldLabel="ФИО увольняемого сотрудника"
          fieldName="p001_employee_fullName"
          fieldID="FormInitiation_p001_employee_fullName"
          fieldPlaceholder="Фамилия Имя Отчество"
          value={formik.values.p001_employee_fullName}
          error={formik.errors.p001_employee_fullName}
          touched={formik.touched.p001_employee_fullName}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_employee_fullName');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormField
          fieldType="text"
          fieldLabel="Должность увольняемого сотрудника"
          fieldName="p001_employee_position"
          fieldID="FormInitiation_p001_employee_position"
          fieldPlaceholder="Должность увольняемого сотрудника"
          value={formik.values.p001_employee_position}
          error={formik.errors.p001_employee_position}
          touched={formik.touched.p001_employee_position}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_employee_position');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormField
          fieldType="text"
          fieldLabel="Телефон увольняемого сотрудника"
          fieldName="p001_employee_phone"
          fieldID="FormInitiation_p001_employee_phone"
          fieldPlaceholder="+7 (XXX) XXX-XX-XX"
          value={formik.values.p001_employee_phone}
          error={formik.errors.p001_employee_phone}
          touched={formik.touched.p001_employee_phone}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_employee_phone');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormField
          fieldType="text"
          fieldLabel="Объект (текущий)"
          fieldName="p001_employee_object"
          fieldID="FormInitiation_p001_employee_object"
          fieldPlaceholder="Объект (текущий)"
          value={formik.values.p001_employee_object}
          error={formik.errors.p001_employee_object}
          touched={formik.touched.p001_employee_object}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_employee_object');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormField
          fieldType="text"
          fieldLabel="Статус на объекте"
          fieldName="p001_employee_statusOnTheObject"
          fieldID="FormInitiation_p001_employee_statusOnTheObject"
          fieldPlaceholder="Статус на объекте"
          value={formik.values.p001_employee_statusOnTheObject}
          error={formik.errors.p001_employee_statusOnTheObject}
          touched={formik.touched.p001_employee_statusOnTheObject}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_employee_statusOnTheObject');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormSelect
          fieldLabel="Причина увольнения"
          fieldName="p001_reasonDismissal"
          fieldID="FormInitiation_p001_reasonDismissal"
          error={formik.errors.p001_reasonDismissal}
          touched={formik.touched.p001_reasonDismissal}
          selectValue={formik.values.p001_reasonDismissal}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_reasonDismissal');
          }}
          disabled={!loading ? '' : 'disabled'}
        >
          <FormSelectOption
            optionValue=""
            optionText="Выберете значение.."
            selected
            disabled
          />
          {reasonsDismissal.map((reasonDismissal) => (
            <FormSelectOption
              reason={reasonDismissal}
              key={reasonDismissal.id}
              optionValue={reasonDismissal.name}
              optionText={reasonDismissal.name}
            />
          ))}
        </FormSelect>

        <FormField
          fieldType="date"
          fieldLabel="Дата предполагаемого увольнения"
          fieldName="p001_dateDismissal"
          fieldID="FormInitiation_p001_dateDismissal"
          fieldPlaceholder="Дата предполагаемого увольнения"
          value={formik.values.p001_dateDismissal}
          error={formik.errors.p001_dateDismissal}
          touched={formik.touched.p001_dateDismissal}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_dateDismissal');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormSearchEmployee
          searchItem="Непосредственный руководитель"
          fieldLabel="Поиск руководителя по ТН"
        />

        <FormField
          fieldType="text"
          fieldLabel="Непосредственный руководитель"
          fieldName="p001_employeeManager_fullName"
          fieldID="FormInitiation_p001_employeeManager_fullName"
          fieldPlaceholder="ФИО руководителя"
          value={formik.values.p001_employeeManager_fullName}
          error={formik.errors.p001_employeeManager_fullName}
          touched={formik.touched.p001_employeeManager_fullName}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_employeeManager_fullName');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormField
          fieldType="text"
          fieldLabel="Телефон непосредственного руководителя"
          fieldName="p001_employeeManager_phone"
          fieldID="FormInitiation_p001_employeeManager_phone"
          fieldPlaceholder="+7 (XXX) XXX-XX-XX"
          value={formik.values.p001_employeeManager_phone}
          error={formik.errors.p001_employeeManager_phone}
          touched={formik.touched.p001_employeeManager_phone}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_employeeManager_phone');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormSearchEmployee
          searchItem="Инициатор увольнения"
          fieldLabel="Поиск инициатора увольнения по ТН"
        />

        <FormField
          fieldType="text"
          fieldLabel="Инициатор увольнения"
          fieldName="p001_initiatorDismissal_fullName"
          fieldID="FormInitiation_p001_initiatorDismissal_fullName"
          fieldPlaceholder="ФИО инициатора увольнения"
          value={formik.values.p001_initiatorDismissal_fullName}
          error={formik.errors.p001_initiatorDismissal_fullName}
          touched={formik.touched.p001_initiatorDismissal_fullName}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_initiatorDismissal_fullName');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormField
          fieldType="text"
          fieldLabel="Телефон инициатора увольнения"
          fieldName="p001_initiatorDismissal_phone"
          fieldID="FormInitiation_p001_initiatorDismissal_phone"
          fieldPlaceholder="+7 (XXX) XXX-XX-XX"
          value={formik.values.p001_initiatorDismissal_phone}
          error={formik.errors.p001_initiatorDismissal_phone}
          touched={formik.touched.p001_initiatorDismissal_phone}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_initiatorDismissal_phone');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormSearchEmployee
          searchItem="Руководитель проекта"
          fieldLabel="Поиск руководителя проекта по ТН"
        />

        <FormField
          fieldType="text"
          fieldLabel="Руководитель проекта"
          fieldName="p001_projectManager_fullName"
          fieldID="FormInitiation_p001_projectManager_fullName"
          fieldPlaceholder="ФИО руководителя проекта"
          value={formik.values.p001_projectManager_fullName}
          error={formik.errors.p001_projectManager_fullName}
          touched={formik.touched.p001_projectManager_fullName}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_projectManager_fullName');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormField
          fieldType="text"
          fieldLabel="Телефон руководителя проекта"
          fieldName="p001_projectManager_phone"
          fieldID="FormInitiation_p001_projectManager_phone"
          fieldPlaceholder="+7 (XXX) XXX-XX-XX"
          value={formik.values.p001_projectManager_phone}
          error={formik.errors.p001_projectManager_phone}
          touched={formik.touched.p001_projectManager_phone}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_projectManager_phone');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormField
          fieldType="email"
          fieldLabel="E-mail руководителя проекта"
          fieldName="p001_projectManager_email"
          fieldID="FormInitiation_p001_projectManager_email"
          fieldPlaceholder="E-mail"
          value={formik.values.p001_projectManager_email}
          error={formik.errors.p001_projectManager_email}
          touched={formik.touched.p001_projectManager_email}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('p001_projectManager_email');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

      </Form>
    </Content>
  );
}

export default inject(({
  UserStore,
  ProcessStore,
  StaffStore,
  TaskStore,
}) => {
  const {
    userFirstName,
    userLastName,
  } = UserStore;

  const {
    process,
  } = ProcessStore;

  const {
    employeeTN,
    employeeFullName,
    employeePosition,
    employeePhone,
    employeeObject,
    employeeStatusOnTheObject,
    employeeBlackList,
    employeeGrayList,

    employeeManagerFullName,
    employeeManagerPhone,

    initiatorDismissalFullName,
    initiatorDismissalPhone,

    projectManagerFullName,
    projectManagerPhone,
    projectManagerEmail,
    projectManagerID,

    handleResetState,
  } = StaffStore;

  const {
    task,
    handleGetTask,
    handleCompleteTask,

    loading,
    errload,
  } = TaskStore;

  return {
    userFirstName,
    userLastName,

    process,

    employeeTN,
    employeeFullName,
    employeePosition,
    employeePhone,
    employeeObject,
    employeeStatusOnTheObject,
    employeeBlackList,
    employeeGrayList,

    employeeManagerFullName,
    employeeManagerPhone,

    initiatorDismissalFullName,
    initiatorDismissalPhone,

    projectManagerFullName,
    projectManagerPhone,
    projectManagerEmail,
    projectManagerID,

    handleResetState,

    task,
    handleGetTask,
    handleCompleteTask,
    loading,
    errload,
  };
})(observer(FormInitiation));