import * as constants from '../../constants/constants';

const {
  CAMUNDA_URL, fixPromise, fixEmptyAnswer,
} = constants;

const TaskUrl = `${CAMUNDA_URL}/task`;

// ! запрос всех задач
// ? https://docs.camunda.org/manual/7.5/reference/rest/task/get-query/
export const getListTasks = async (token) => fetch(`${TaskUrl}`, {
  method: 'GET',
  headers: {
    Authorization: token,
    'Content-Type': 'application/json',
  },
}).then((res) => fixPromise(res));

// ! запрос задачи по id
// ? https://docs.camunda.org/manual/7.5/reference/rest/task/get/
export const getTask = async (token, taskID) => fetch(`${TaskUrl}/?executionId=${taskID}`, {
  method: 'GET',
  headers: {
    Authorization: token,
    'Content-Type': 'application/json',
  },
}).then((res) => fixPromise(res));

// ! выполнить задачу
// ? https://docs.camunda.org/manual/7.5/reference/rest/task/post-complete/
// запрос ничего не возвращает (204 No Content)
export const completeTask = ({ token, data, id }) => fetch(`${TaskUrl}/${id}/complete`, {
  method: 'POST',
  headers: {
    Authorization: token,
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(
    {
      variables:
      {
        p001_processName: { value: data.p001_processName },
        p001_employee_fullName: { value: data.p001_employee_fullName },
        p001_employee_position: { value: data.p001_employee_position },
        p001_employee_TN: { value: data.p001_employee_TN },
        p001_employee_phone: { value: data.p001_employee_phone },
        p001_employee_object: { value: data.p001_employee_object },
        p001_employee_statusOnTheObject: { value: data.p001_employee_statusOnTheObject },
        p001_reasonDismissal: { value: data.p001_reasonDismissal },
        p001_dateDismissal: { value: data.p001_dateDismissal },
        p001_employeeManager_fullName: { value: data.p001_employeeManager_fullName },
        p001_employeeManager_phone: { value: data.p001_employeeManager_phone },
        p001_initiatorDismissal_fullName: { value: data.p001_initiatorDismissal_fullName },
        p001_initiatorDismissal_phone: { value: data.p001_initiatorDismissal_phone },
        p001_projectManager_fullName: { value: data.p001_projectManager_fullName },
        p001_projectManager_phone: { value: data.p001_projectManager_phone },
        p001_projectManager_email: { value: data.p001_projectManager_email },
        p001_projectManager_id: { value: data.p001_projectManager_id },
        p001_processInitiator: { value: data.p001_processInitiator },
        p001_eventType: { value: data.p001_eventType },

        p001_blackListDecision: { value: data.p001_blackListDecision },
        p001_grayListDecision: { value: data.p001_grayListDecision },
        p001_RPComment: { value: data.p001_RPComment },
        p001_approveCheck: { value: data.p001_approveCheck },

        p001_initiatorDismissal_comment: { value: data.p001_initiatorDismissal_comment },
        p001_employeeManager_comment: { value: data.p001_employeeManager_comment },
        p001_employee_comment: { value: data.p001_employee_comment },
        p001_112_comment: { value: data.p001_112_comment },

        p001_dismissalDecision: { value: data.p001_dismissalDecision },
        p001_proceedDecision: { value: data.p001_proceedDecision },
      },
    },
  ),
}).then((res) => fixEmptyAnswer(res));

// ! запрос полей формы задачи
// ? https://docs.camunda.org/manual/7.5/reference/rest/task/get-form-variables/
export const getTaskFormVariables = (token, taskID) => fetch(`${TaskUrl}/${taskID}/form-variables`, {
  method: 'GET',
  headers: {
    Authorization: token,
    'Content-Type': 'application/json',
  },
}).then((res) => fixPromise(res));

// ! редактирование значения таска
// ? https://docs.camunda.org/manual/7.5/reference/rest/task/variables/put-task-variable/
export const updateTaskVariable = (token, taskID, varName) => fetch(`${TaskUrl}/${taskID}/variables/${varName}`, {
  method: 'PUT',
  headers: {
    Authorization: token,
    'Content-Type': 'application/json',
  },
}).then((res) => fixEmptyAnswer(res));

export const testReq = async (data) => fetch('https://webhook.site/de197745-185f-4bf6-8892-c72b9541a0ac', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
}).then((res) => fixPromise(res));