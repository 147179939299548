import React from 'react';

import { useFormik } from 'formik';
import { inject, observer } from 'mobx-react';

import FormSearch from '../../../../../utils/ui/FormSearch/FormSearch';
import FormSearchField from '../../../../../utils/ui/FormSearchField/FormSearchField';

import * as validation from './validation';

const {
  validationsSchema,
} = validation;

function FormSearchEmployee({
  searchItem,
  fieldLabel,

  loading,
  errload,
  handleGetEmployeeByTN,
  handleGetEmployeeManagerByTN,
  handleGetInitiatorDismissalByTN,
  handleGetProjectManagerByTN,
}) {
  const accessKey = localStorage.getItem('accessKey');

  const formik = useFormik({
    initialValues: {
      employee_TN: '',
    },
    validationSchema: validationsSchema,
    onSubmit: async (values) => {
      if (searchItem === 'Cотрудник') {
        await handleGetEmployeeByTN(accessKey, values.employee_TN);
      }

      if (searchItem === 'Непосредственный руководитель') {
        await handleGetEmployeeManagerByTN(accessKey, values.employee_TN);
      }

      if (searchItem === 'Инициатор увольнения') {
        await handleGetInitiatorDismissalByTN(accessKey, values.employee_TN);
      }

      if (searchItem === 'Руководитель проекта') {
        await handleGetProjectManagerByTN(accessKey, values.employee_TN);
      }
    },
  });

  return (
    <FormSearch>

      <FormSearchField
        buttonText="Найти"
        fieldType="numder"
        fieldLabel={fieldLabel}
        fieldName="employee_TN"
        fieldID="FormSearchEmployee_employee_TN"
        fieldPlaceholder="Таблельный номер"
        value={formik.values.employee_TN}
        error={formik.errors.employee_TN || errload}
        touched={formik.touched.employee_TN}
        onBlur={formik.handleBlur}
        dirty={formik.dirty}
        valid={formik.isValid}
        onChange={(evt) => {
          formik.handleChange(evt);
          formik.setFieldTouched('employee_TN');
        }}
        onSubmit={formik.handleSubmit}
        loading={loading}

      />

    </FormSearch>
  );
}

export default inject(({
  StaffStore,
}) => {
  const {
    loading,
    errload,
    handleGetEmployeeByTN,
    handleGetEmployeeManagerByTN,
    handleGetInitiatorDismissalByTN,
    handleGetProjectManagerByTN,
  } = StaffStore;

  return {
    loading,
    errload,
    handleGetEmployeeByTN,
    handleGetEmployeeManagerByTN,
    handleGetInitiatorDismissalByTN,
    handleGetProjectManagerByTN,
  };
})(observer(FormSearchEmployee));