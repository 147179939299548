/* eslint-disable no-console */
/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from 'mobx';

import * as ProcessDefinitionApi from '../utils/Api/CamundaApi/ProcessDefinitionApi';
import * as ErrMessages from '../utils/constants/errMessages';

const {
  getListProcessDefinition,
  startProcessInstance,
} = ProcessDefinitionApi;

const {
  ProcessDefinitionErrMessage,
} = ErrMessages;

export default class ProcessStore {
  processes = [];
  process = {};
  processErrMessage = '';

  constructor() {
    makeAutoObservable(this);
  }

  handleListProcesses = async (token) => {
    this.processErrMessage = '';

    await getListProcessDefinition(token)
      .then((res) => {
        const arr = res.filter(
          (process) => process.key !== 'ReviewInvoice'
            && process.key !== 'invoice',
        );

        this.processes = arr;

        if (arr.length === 0) {
          this.processErrMessage = ProcessDefinitionErrMessage.NotFoundError;
        }
      })
      .catch(() => {
        this.processes = [];
        this.processErrMessage = ProcessDefinitionErrMessage.DownloadError;
      });
  };

  handleStartProcess = async (token, processID) => {
    await startProcessInstance(token, processID)
      .then((res) => {
        this.process = res;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}
