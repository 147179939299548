import * as yup from 'yup';

// eslint-disable-next-line no-unused-vars
import * as validMessages from '../../../../utils/constants/validMessages';

const {
  validMessage,
} = validMessages;

// eslint-disable-next-line import/prefer-default-export
export const validationsSchema = yup.object().shape({
  /*
  p001_blackListDecision: yup.boolean()
    .typeError('Должно быть "true" или "false"'),
  p001_grayListDecision: yup.boolean()
    .typeError('Должно быть "true" или "false"'),
  */
  p001_RPComment: yup.string()
    .typeError(validMessage.typeStringErr)
    .min(5, 'Должно быть не менее 5 символов')
    .matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
    .required(validMessage.requiredErr),
  /*
  p001_approveCheck: yup.boolean()
    .typeError('Должно быть "true"'),
  */
});
