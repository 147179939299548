import * as yup from 'yup';

import * as validMessages from '../../../../../utils/constants/validMessages';

const {
  validMessage,
} = validMessages;

// eslint-disable-next-line import/prefer-default-export
export const validationsSchema = yup.object().shape({
  employee_TN: yup.number()
    .typeError(validMessage.typeNumberErr)
    .required(validMessage.requiredErr),
});
