import React from 'react';

import Modal from '../../../utils/ui/Modal/Modal';

function DismissalDecisionModal({
  values,
  isModalDismissalDecision,
  handleCloseModalDismissalDecision,
}) {
  return (
    <Modal
      isActiveModal={isModalDismissalDecision}
      handleCloseModal={handleCloseModalDismissalDecision}
      title="Решение по увольнению сотрудника"
    >
      <div className="modal__wrapper">
        <span className="modal__key">Сотрудник</span>
        <p className="modal__value">
          {`${values.p001_employee_fullName}, тел: ${values.p001_employee_phone}`}
        </p>
      </div>

      <div className="modal__wrapper">
        <span className="modal__key">Решение по увольнению</span>
        {values.p001_dismissalDecision === 'Уволить'
          ? (
            <p className="modal__value">
              {`${values.p001_dismissalDecision}`}
            </p>
          ) : (
            <p className="modal__value">
              {`${values.p001_dismissalDecision}: ${values.p001_proceedDecision}`}
            </p>
          )}
      </div>

      <div className="modal__wrapper">
        <span className="modal__key">Причина увольнения</span>
        <p className="modal__value">
          {`${values.p001_reasonDismissal}`}
        </p>
      </div>

    </Modal>
  );
}

export default DismissalDecisionModal;
