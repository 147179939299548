export const ProcessDefinitionErrMessage = {
  NotFoundError: 'Нет процессов для запуска',
  DownloadError: 'Ошибка при загрузке процессов',
};

export const TaskErrMessage = {
  NotFoundError: 'Задачи отсутствуют',
  DownloadError: 'Ошибка при загрузке задач',
};

export const errorMessage = {
  tokenErr: 'Не удалось передать токен.',
  serverErr: 'Что-то пошло не так. Попробуйте еще раз',
};