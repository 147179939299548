import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import './Header.scss';

import { inject, observer } from 'mobx-react';

import { path } from '../../utils/constants/constants';

function Header({
  loggedIn,
  handleLogOut,
}) {
  const location = useLocation();

  return (
    <header className="header">
      <div className="header__menu">
        <nav className="header__nav-location">
          <ul className="header__location-items">
            <li className="header__location-item">
              <NavLink
                to={path.main}
                className={`header__btn-navlink
                  ${location.pathname === path.main ? 'header__btn-navlink_active' : ''}
                `}
              >
                Главная
              </NavLink>
            </li>
            <li className="header__location-item">
              <NavLink
                to={path.main}
                className={`header__btn-navlink
                  ${location.pathname !== path.main ? 'header__btn-navlink_active' : ''}
                `}
              >
                Camunda
              </NavLink>
            </li>
          </ul>
        </nav>

        {loggedIn ? (
          <nav className="header__nav-sign">
            <ul className="header__location-items">
              <li className="header__location-item">
                <NavLink
                  to={path.login}
                  className="header__btn-navlink"
                  onClick={handleLogOut}
                >
                  Выйти
                </NavLink>
              </li>
            </ul>
          </nav>
        ) : (
          <></>
        )}

      </div>
    </header>
  );
}

export default inject(({ UserStore }) => {
  const {
    loggedIn,
    handleLogOut,
  } = UserStore;

  return {
    loggedIn,
    handleLogOut,
  };
})(observer(Header));