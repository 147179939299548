import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { inject, observer } from 'mobx-react';
import CryptoJS from 'crypto-js';

import Content from '../Content';
import CardInfo from '../../../../utils/ui/CardInfo/CardInfo';
import Form from '../../../../utils/ui/Form/Form';
import FormCheckbox from '../../../../utils/ui/FormCheckbox/FormCheckbox';
import FormField from '../../../../utils/ui/FormField/FormField';

import * as validation from './validation';
import { path, secret } from '../../../../utils/constants/constants';

const {
  validationsSchema,
} = validation;

function FormApprovalDecision({
  task,
  taskValues,
  handleCompleteTask,

  loading,
  errload,
}) {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      // ? переменные из других тасков
      p001_processName: taskValues.p001_processName,
      p001_employee_fullName: taskValues.p001_employee_fullName,
      p001_employee_position: taskValues.p001_employee_position,
      p001_employee_TN: taskValues.p001_employee_TN,
      p001_employee_phone: taskValues.p001_employee_phone,
      p001_employee_object: taskValues.p001_employee_object,
      p001_employee_statusOnTheObject: taskValues.p001_employee_statusOnTheObject,
      p001_dateDismissal: taskValues.p001_dateDismissal,
      p001_employeeManager_fullName: taskValues.p001_employeeManager_fullName,
      p001_employeeManager_phone: taskValues.p001_employeeManager_phone,
      p001_initiatorDismissal_fullName: taskValues.p001_initiatorDismissal_fullName,
      p001_initiatorDismissal_phone: taskValues.p001_initiatorDismissal_phone,
      p001_projectManager_fullName: taskValues.p001_projectManager_fullName,
      p001_projectManager_phone: taskValues.p001_projectManager_phone,
      p001_projectManager_email: taskValues.p001_projectManager_email,
      p001_projectManager_id: taskValues.p001_projectManager_id,
      p001_processInitiator: taskValues.p001_processInitiator,
      p001_eventType: taskValues.p001_eventType,

      // ! переменные этого таска
      p001_blackListDecision: taskValues.p001_blackListDecision,
      p001_grayListDecision: taskValues.p001_grayListDecision,
      p001_RPComment: taskValues.p001_RPComment,
      p001_approveCheck: taskValues.p001_approveCheck,

      p001_initiatorDismissal_comment: taskValues.p001_initiatorDismissal_comment,
      p001_employeeManager_comment: taskValues.p001_employeeManager_comment,
      p001_reasonDismissal: taskValues.p001_reasonDismissal,

      p001_employee_comment: taskValues.p001_employee_comment,
      p001_112_comment: taskValues.p001_112_comment,

      p001_dismissalDecision: taskValues.p001_dismissalDecision,
      p001_proceedDecision: taskValues.p001_proceedDecision,
    },
    validationSchema: validationsSchema,
    onSubmit: async (values) => {
      const getToken = localStorage.getItem('token');
      const bytesToken = CryptoJS.AES.decrypt(getToken, secret);
      const token = JSON.parse(bytesToken.toString(CryptoJS.enc.Utf8));

      await handleCompleteTask(token, values, task)
        .then(() => navigate(path.main))
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err.message)); // вывести ошибку в форме
    },
  });

  useEffect(() => {
  }, [formik.values.p001_approveCheck]);

  return (
    <Content
      titleSection="Подтвердить решение по увольнению"
    >
      <div className="card-info">

        <CardInfo
          valuesTask={taskValues}
        />

        <Form
          buttonText={loading ? 'Сохранение...' : 'Отправить'}
          onSubmit={formik.handleSubmit}
          disabled={!formik.isValid && !formik.values.p001_approveCheck}
          loading={loading}
          error={errload}
        >

          <FormCheckbox
            fieldLabel="Черный список"
            fieldName="p001_blackListDecision"
            fieldID="FormApprovalDecision_p001_blackListDecision"
            error={formik.errors.p001_blackListDecision}
            touched={formik.touched.p001_blackListDecision}
            value={formik.values.p001_blackListDecision}
            onBlur={formik.handleBlur}
            onChange={(evt) => {
              formik.handleChange(evt);
              formik.setFieldTouched('p001_blackListDecision');
            }}
            checked={formik.values.p001_blackListDecision}
            disabled={!loading ? '' : 'disabled'}
          />

          <FormCheckbox
            fieldLabel="Серый список"
            fieldName="p001_grayListDecision"
            fieldID="FormApprovalDecision_p001_grayListDecision"
            error={formik.errors.p001_grayListDecision}
            touched={formik.touched.p001_grayListDecision}
            value={formik.values.p001_grayListDecision}
            onBlur={formik.handleBlur}
            onChange={(evt) => {
              formik.handleChange(evt);
              formik.setFieldTouched('p001_grayListDecision');
            }}
            checked={formik.values.p001_grayListDecision}
            disabled={!loading ? '' : 'disabled'}
          />

          <FormField
            fieldType="text"
            fieldLabel="Комментарий руководителя проекта"
            fieldName="p001_RPComment"
            fieldID="FormApprovalDecision_p001_RPComment"
            fieldPlaceholder="Причина увольнения"
            value={formik.values.p001_RPComment}
            error={formik.errors.p001_RPComment}
            touched={formik.touched.p001_RPComment}
            onBlur={formik.handleBlur}
            onChange={(evt) => {
              formik.handleChange(evt);
              formik.setFieldTouched('p001_RPComment');
            }}
            disabled={!loading ? '' : 'disabled'}
          />

          <FormCheckbox
            fieldLabel="Решение об увольнении подтверждаю"
            fieldName="p001_approveCheck"
            fieldID="FormApprovalDecision_p001_approveCheck"
            error={formik.errors.p001_approveCheck}
            touched={formik.touched.p001_approveCheck}
            value={formik.values.p001_approveCheck}
            onBlur={formik.handleBlur}
            onChange={(evt) => {
              formik.handleChange(evt);
              formik.setFieldTouched('p001_approveCheck');
            }}
            disabled={!loading ? '' : 'disabled'}
          />

        </Form>
      </div>
    </Content>
  );
}

export default inject(({
  TaskStore,
}) => {
  const {
    task,
    taskValues,
    handleCompleteTask,
    handleSavedValuesTask,

    loading,
    errload,
  } = TaskStore;

  return {
    task,
    taskValues,
    handleCompleteTask,
    handleSavedValuesTask,
    loading,
    errload,
  };
})(observer(FormApprovalDecision));
