import React from 'react';
import {
  Routes, Route, Navigate, useLocation,
} from 'react-router-dom';

import './Main.scss';

import { inject, observer } from 'mobx-react';

import Login from './Content/Login/Login';

import Board from './Board/Board';
import Processes from './Content/Processes/Processes';
import FormInitiation from './Content/FormInitiation/FormInitiation';
import FormApprovalDecision from './Content/FormApprovalDecision/FormApprovalDecision';
import FormCollectInfo from './Content/FormCollectInfo/FormCollectInfo';
import FormDismissalDecision from './Content/FormDismissalDecision/FormDismissalDecision';

import { path } from '../../utils/constants/constants';

function Main({
  loggedIn,
}) {
  const location = useLocation();

  return (
    <main className="main">
      {location.pathname !== path.login && (
        <Board />
      )}

      <Routes>
        <Route
          path={path.login}
          element={loggedIn ? (
            <Navigate to={path.main} />
          ) : (
            <Login />
          )}
        />

        <Route
          path={path.main}
          element={loggedIn ? (
            <Processes />
          ) : (
            <Navigate to={path.login} />
          )}
        />

        <Route
          path={path.formInitiation}
          element={loggedIn ? (
            <FormInitiation />
          ) : (
            <Navigate to={path.login} />
          )}
        />

        <Route
          path={path.formApprovalDecision}
          element={loggedIn ? (
            <FormApprovalDecision />
          ) : (
            <Navigate to={path.login} />
          )}
        />

        <Route
          path={path.formCollectInfo}
          element={loggedIn ? (
            <FormCollectInfo />
          ) : (
            <Navigate to={path.login} />
          )}
        />

        <Route
          path={path.formDismissalDecision}
          element={loggedIn ? (
            <FormDismissalDecision />
          ) : (
            <Navigate to={path.login} />
          )}
        />

        <Route
          path="*"
          element={loggedIn ? (
            <Navigate to={path.main} />
          ) : (
            <Navigate to={path.login} />
          )}
        />
      </Routes>

    </main>
  );
}

export default inject(({ UserStore }) => {
  const { loggedIn } = UserStore;

  return {
    loggedIn,
  };
})(observer(Main));
