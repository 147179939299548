import * as yup from 'yup';

import * as validMessages from '../../../../utils/constants/validMessages';

const {
  validMessage,
} = validMessages;

// eslint-disable-next-line import/prefer-default-export
export const validationsSchema = yup.object().shape({
  p001_employee_fullName: yup.string()
    .typeError(validMessage.typeStringErr)
    .min(5, 'Должно быть не менее 5 символов')
    .matches(/^[А-Яа-яЁё ]+$/, { message: validMessage.cyrillicErr })
    .required(validMessage.requiredErr),
  p001_employee_position: yup.string()
    .typeError(validMessage.typeStringErr)
    .matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
    .required(validMessage.requiredErr),
  p001_employee_TN: yup.number()
    .typeError(validMessage.typeNumberErr)
    .required(validMessage.requiredErr),
  p001_employee_phone: yup.number()
    .typeError(validMessage.typeNumberErr)
    .min(8, 'Должно быть не менее 8 символов')
    // ! разобраться - почему неправильно отрабатывает max
    // .max(20, 'Должно быть не более 20 символов')
    .required(validMessage.requiredErr),
  p001_employee_object: yup.string()
    .typeError(validMessage.typeStringErr)
    .matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
    .required(validMessage.requiredErr),
  p001_employee_statusOnTheObject: yup.string()
    .typeError(validMessage.typeStringErr)
    .matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
    .required(validMessage.requiredErr),
  p001_reasonDismissal: yup.string()
    .typeError('Значение должно быть выбрано')
    .matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
    .required(validMessage.requiredErr),
  p001_dateDismissal: yup.date()
    .typeError(validMessage.typeDateErr)
    .required(validMessage.requiredErr),
  p001_employeeManager_fullName: yup.string()
    .typeError(validMessage.typeStringErr)
    .min(5, 'Должно быть не менее 5 символов')
    .matches(/^[А-Яа-я ]+$/, { message: validMessage.cyrillicErr })
    .required(validMessage.requiredErr),
  p001_employeeManager_phone: yup.number()
    .typeError(validMessage.typeNumberErr)
    .min(8, 'Должно быть не менее 8 символов')
    // ! разобраться - почему неправильно отрабатывает max
    // .max(20, 'Должно быть не более 20 символов')
    .required(validMessage.requiredErr),
  p001_initiatorDismissal_fullName: yup.string()
    .typeError(validMessage.typeStringErr)
    .min(5, 'Должно быть не менее 5 символов')
    .matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
    .required(validMessage.requiredErr),
  p001_initiatorDismissal_phone: yup.number()
    .typeError(validMessage.typeNumberErr)
    .min(8, 'Должно быть не менее 8 символов')
    // ! разобраться - почему неправильно отрабатывает max
    // .max(20, 'Должно быть не более 20 символов')
    .required(validMessage.requiredErr),
  p001_projectManager_fullName: yup.string()
    .typeError('Значение должно быть выбрано')
    .matches(/^[А-Яа-я ]+$/, { message: validMessage.cyrillicErr })
    .required(validMessage.requiredErr),
  p001_projectManager_phone: yup.number()
    .typeError(validMessage.typeNumberErr)
    .min(8, 'Должно быть не менее 8 символов')
    // ! разобраться - почему неправильно отрабатывает max
    // .max(20, 'Должно быть не более 20 символов')
    .required(validMessage.requiredErr),
  p001_projectManager_email: yup.string()
    .typeError(validMessage.typeStringErr)
    .email('Допускается только E-mail')
    .min(5, 'Должно быть не менее 5 символов')
    .required(validMessage.requiredErr),
});
