/* eslint-disable no-useless-constructor */
/* eslint-disable no-empty-function */
import UserStore from './userStore';
import ProcessStore from './ProcessStore';
import TaskStore from './TaskStore';
import GroupStore from './GroupStore';
import StaffStore from './StaffStore';

class RootStore {
  constructor() {
  }

  UserStore = new UserStore();

  TaskStore = new TaskStore();

  ProcessStore = new ProcessStore();

  GroupStore = new GroupStore();

  StaffStore = new StaffStore();
}

const stores = new RootStore();

export default stores;
