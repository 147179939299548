import * as constants from '../../constants/constants';

const {
  POLATI_API,
  POLATI_TOKEN,
  POLATI2_URL,
  fixPromise,
  // eslint-disable-next-line no-unused-vars
  fixEmptyAnswer,
} = constants;

const StaffUrl = `${POLATI_API}/staff/staff`;
const AuthUrl = `${POLATI_API}/auth`;
const crmStaff = `${POLATI2_URL}/crm/staff`;

// {{baseURL}}staff/staff/getByColOne?token={{token}}&col=uid&value=168
export const getStaffByColOne = async (accessKey, TN) => fetch(`${StaffUrl}/getByColOne?token=${POLATI_TOKEN}&col=uid&value=${TN}`, {
  method: 'GET',
  headers: {
    'Access-Key': `${accessKey}`,
    'Content-Type': 'application/json',
  },
}).then((res) => fixPromise(res));

export const getListEvents = async (accessKey, TN) => fetch(`${StaffUrl}/getListEvents?token=${POLATI_TOKEN}&filter[order][]=uid&filter[select][]=id&filter[select][]=name&filter[select][]=surname&filter[select][]=patronymic&filter[where][uid][value][start]=${TN}&filter[where][uid][value][end]=${TN}&filter[where][uid][comparison]=BETWEEN`, {
  method: 'GET',
  headers: {
    'Access-Key': `${accessKey}`,
    'Content-Type': 'application/json',
  },
}).then((res) => fixPromise(res));

export const getAccessKey = async (token) => fetch(`${AuthUrl}/?action=access_key&token=${token}`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => fixPromise(res));

// запрос ничего не возвращает
export const sendEmailToHRdepartment = async (data) => fetch(`${crmStaff}/trigger?action=staff_comunda_dismiss_alert&access_key=${POLATI_TOKEN}`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  body: data,
}).then((res) => fixEmptyAnswer(res));
