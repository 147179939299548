/* eslint-disable no-console */
/* eslint-disable lines-between-class-members */
// eslint-disable-next-line no-unused-vars
import { makeAutoObservable, toJS } from 'mobx';
// eslint-disable-next-line no-unused-vars
import formurlencoded from 'form-urlencoded';

import * as TaskApi from '../utils/Api/CamundaApi/TaskApi';
import * as StaffApi from '../utils/Api/PolatiApi/StaffApi';
import * as ErrMessages from '../utils/constants/errMessages';

const {
  getListTasks,
  getTask,
  completeTask,
  getTaskFormVariables,
} = TaskApi;

// eslint-disable-next-line no-unused-vars
const { sendEmailToHRdepartment } = StaffApi;

const { TaskErrMessage, errorMessage } = ErrMessages;

export default class TaskStore {
  tasks = [];
  task = {};
  taskValues = {};
  taskErrMessage = '';

  loading = false;
  errload = '';

  constructor() {
    makeAutoObservable(this);
  }

  handleListTasks = async (token) => {
    this.taskErrMessage = '';

    await getListTasks(token)
      .then((res) => {
        const arr = res.filter(
          (task) => task.taskDefinitionKey !== 'approveInvoice'
            && task.taskDefinitionKey !== 'assignReviewer'
            && task.taskDefinitionKey !== 'prepareBankTransfer',
        );

        this.tasks = arr;

        if (arr.length === 0) {
          this.taskErrMessage = TaskErrMessage.NotFoundError;
        }
      })
      .catch((err) => {
        console.log(err.message);

        this.tasks = [];
        this.taskErrMessage = TaskErrMessage.DownloadError;
      });
  };

  handleGetTask = async (token, taskID) => {
    await getTask(token, taskID)
      .then((res) => {
        this.handleSavedTask(res[0]);
      })
      .catch((err) => console.log(err.message));
  };

  handleCompleteTask = async (token, data, task) => {
    this.loading = true;
    this.errload = '';

    const { id, taskDefinitionKey } = task;

    await completeTask({ token, data, id })
      .then(() => {
        this.handleListTasks(token); // запросить/обновить список задач

        this.errload = '';

        // ! закомментировано до тех пор
        // ! пока задача по отправке письма РП
        // ! не будет решена на бэке

        if (taskDefinitionKey === 'p001_formInitiation') {
          const fromData = formurlencoded(data);

          console.log('p001_formInitiation');
          sendEmailToHRdepartment(fromData);
        }

        if (taskDefinitionKey === 'p001_formDismissalDecision') {
          const fromData = formurlencoded(data);

          console.log('p001_formDismissalDecision');
          sendEmailToHRdepartment(fromData);
        }

        /*
        if (taskDefinitionKey === 'p001_formDismissalDecision') {
          const fromData = formurlencoded(data);
          console.log(data);
          sendEmailToHRdepartment(fromData);
        }
        */
      })
      .catch((err) => {
        console.log(err.message);

        this.errload = errorMessage.serverErr;
      })
      .finally(() => {
        this.loading = false;
      });
  };

  handleValuesTask = async (token, taskID) => {
    await getTaskFormVariables(token, taskID)
      .then((res) => {
        this.taskValues = {
          p001_processName: res.p001_processName.value,
          p001_employee_fullName: res.p001_employee_fullName.value,
          p001_employee_position: res.p001_employee_position.value,
          p001_employee_TN: res.p001_employee_TN.value,
          p001_employee_phone: res.p001_employee_phone.value,
          p001_employee_object: res.p001_employee_object.value,
          p001_employee_statusOnTheObject:
            res.p001_employee_statusOnTheObject.value,
          p001_reasonDismissal: res.p001_reasonDismissal.value,
          p001_dateDismissal: res.p001_dateDismissal.value,
          p001_employeeManager_fullName:
            res.p001_employeeManager_fullName.value,
          p001_employeeManager_phone: res.p001_employeeManager_phone.value,
          p001_initiatorDismissal_fullName:
            res.p001_initiatorDismissal_fullName.value,
          p001_initiatorDismissal_phone:
            res.p001_initiatorDismissal_phone.value,
          p001_projectManager_fullName: res.p001_projectManager_fullName.value,
          p001_projectManager_phone: res.p001_projectManager_phone.value,
          p001_projectManager_email: res.p001_projectManager_email.value,
          p001_projectManager_id: res.p001_projectManager_id.value,
          p001_processInitiator: res.p001_processInitiator.value,
          p001_eventType: res.p001_eventType.value,

          p001_blackListDecision: res.p001_blackListDecision.value,
          p001_grayListDecision: res.p001_grayListDecision.value,
          p001_RPComment: res.p001_RPComment.value,
          p001_approveCheck: res.p001_approveCheck.value,

          p001_initiatorDismissal_comment:
            res.p001_initiatorDismissal_comment.value,
          p001_employeeManager_comment: res.p001_employeeManager_comment.value,
          p001_employee_comment: res.p001_employee_comment.value,
          p001_112_comment: res.p001_112_comment.value,

          p001_dismissalDecision: res.p001_dismissalDecision.value,
          p001_proceedDecision: res.p001_proceedDecision.value,
        };
      })
      .catch((err) => console.log(`Не удалось получить данные задачи ${err.message}`));
  };

  handleSavedTask = (obj) => {
    this.task = obj;
  };

  handleSavedValuesTask = (obj) => {
    this.taskValues = obj;
  };
}
