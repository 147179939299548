/* eslint-disable react/prop-types */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './Form.scss';

import { path } from '../../constants/constants';

function Form({
  children,
  buttonText,
  onSubmit,
  disabled,
  loading,
  error,
}) {
  const location = useLocation();

  return (
    <form
      action="#"
      className="form"
    >

      {children}

      <div className="form__btn-wrap">
        {error
          ? (
            <span
              className="form__btn-submit-err"
            >
              {error}
            </span>
          ) : (<></>)}

        <button
          type="submit"
          aria-label={buttonText}
          onClick={onSubmit}
          className={
            disabled || loading
              ? 'form__btn-submit_disabled'
              : 'form__btn-submit'
          }
          disabled={disabled || loading}
        >
          {buttonText}
        </button>

        {location.pathname !== path.login && (
          <Link
            to="/"
            className="form__link"
          >
            На главную
          </Link>
        )}
      </div>
    </form>
  );
}

export default Form;
