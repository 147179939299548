// eslint-disable-next-line import/prefer-default-export
export const validMessage = {
  typeStringErr: 'Введенное значение должно быть строкой',
  typeNumberErr: 'Допускаются только числа',
  typeBooleanErr: 'Допускаются только булево значение',
  typeDateErr: 'Введенное значение должно быть датой',
  cyrillicErr: 'Допускается только кириллица',
  cyrillicNumErr: 'Допускаются только кириллица и цифры',
  latinNumErr: 'Допускаются только латиница и цифры',
  requiredErr: 'Обязательное поле',
};
