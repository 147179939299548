/* eslint-disable camelcase */
import React from 'react';
import { useLocation } from 'react-router-dom';

import './CardInfo.scss';

import { path } from '../../constants/constants';

function CardInfo({
  valuesTask,
}) {
  const location = useLocation();

  return (
    <div className="card-info__card">
      <h4 className="card-info__title">Данные задачи:</h4>
      {location.pathname === path.formApprovalDecision && (
        <>
          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Наименование процесса:</span>
            <p className="card-info__card-value">{valuesTask.p001_processName}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">ФИО сотрудника, телефон</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_employee_fullName}, тел. ${valuesTask.p001_employee_phone}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Табельный номер</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_TN}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Должность сотрудника</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_position}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Объект, ЦО/подразделение</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_object}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Статус на объекте</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_statusOnTheObject}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Причина увольнения</span>
            <p className="card-info__card-value">{valuesTask.p001_reasonDismissal}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Дата предполагаемого увольнения</span>
            <p className="card-info__card-value">{valuesTask.p001_dateDismissal}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Непосредственный руководитель, телефон</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_employeeManager_fullName}, тел. ${valuesTask.p001_employeeManager_phone}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Инициатор увольнения, телефон</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_initiatorDismissal_fullName}, тел. ${valuesTask.p001_initiatorDismissal_phone}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Руководитель проекта, телефон, e-mail</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_projectManager_fullName}, тел. ${valuesTask.p001_projectManager_phone}, ${valuesTask.p001_projectManager_email}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Инициатор процесса</span>
            <p className="card-info__card-value">{valuesTask.p001_processInitiator}</p>
          </div>
        </>
      )}

      {location.pathname === path.formCollectInfo && (
        <>
          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Наименование процесса:</span>
            <p className="card-info__card-value">{valuesTask.p001_processName}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">ФИО сотрудника, телефон</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_employee_fullName}, тел. ${valuesTask.p001_employee_phone}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Табельный номер</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_TN}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Должность сотрудника</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_position}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Объект, ЦО/подразделение</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_object}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Статус на объекте</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_statusOnTheObject}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Причина увольнения</span>
            <p className="card-info__card-value">{valuesTask.p001_reasonDismissal}</p>
          </div>

          {valuesTask.p001_blackListDecision || valuesTask.p001_grayListDecision ? (
            <div className="card-info__card-wrap-val">
              <span className="card-info__card-key">Списки:</span>
              {valuesTask.p001_blackListDecision
                && (<p className="card-info__card-value">- Черный список</p>)}
              {valuesTask.p001_grayListDecision
                && (<p className="card-info__card-value">- Серый список</p>)}
            </div>
          ) : (<></>)}

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Дата предполагаемого увольнения</span>
            <p className="card-info__card-value">{valuesTask.p001_dateDismissal}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Непосредственный руководитель, телефон</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_employeeManager_fullName}, тел. ${valuesTask.p001_employeeManager_phone}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Инициатор увольнения, телефон</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_initiatorDismissal_fullName}, тел. ${valuesTask.p001_initiatorDismissal_phone}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Руководитель проекта, телефон, e-mail</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_projectManager_fullName}, тел. ${valuesTask.p001_projectManager_phone}, ${valuesTask.p001_projectManager_email}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Комментарий руководителя проекта:</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_RPComment}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Инициатор процесса</span>
            <p className="card-info__card-value">{valuesTask.p001_processInitiator}</p>
          </div>
        </>
      )}

      {location.pathname === path.formDismissalDecision && (
        <>
          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Наименование процесса:</span>
            <p className="card-info__card-value">{valuesTask.p001_processName}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">ФИО сотрудника, телефон</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_employee_fullName}, тел. ${valuesTask.p001_employee_phone}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Табельный номер</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_TN}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Должность сотрудника</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_position}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Объект, ЦО/подразделение</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_object}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Статус на объекте</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_statusOnTheObject}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Причина увольнения</span>
            <p className="card-info__card-value">{valuesTask.p001_reasonDismissal}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Комментарий от сотрудника:</span>
            <p className="card-info__card-value">{valuesTask.p001_employee_comment}</p>
          </div>

          {valuesTask.p001_blackListDecision || valuesTask.p001_grayListDecision ? (
            <div className="card-info__card-wrap-val">
              <span className="card-info__card-key">Списки:</span>
              {valuesTask.p001_blackListDecision
                && (<p className="card-info__card-value">- Черный список</p>)}
              {valuesTask.p001_grayListDecision
                && (<p className="card-info__card-value">- Серый список</p>)}
            </div>
          ) : (<></>)}

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Дата предполагаемого увольнения</span>
            <p className="card-info__card-value">{valuesTask.p001_dateDismissal}</p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Непосредственный руководитель, телефон</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_employeeManager_fullName}, тел. ${valuesTask.p001_employeeManager_phone}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Комментарий непосредственного руководителя:</span>
            <p className="card-info__card-value">
              {valuesTask.p001_employeeManager_comment}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Инициатор увольнения, телефон</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_initiatorDismissal_fullName}, тел. ${valuesTask.p001_initiatorDismissal_phone}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Комментарий инициатора увольнения:</span>
            <p className="card-info__card-value">
              {valuesTask.p001_initiatorDismissal_comment}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Руководитель проекта, телефон, e-mail</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_projectManager_fullName}, тел. ${valuesTask.p001_projectManager_phone}, ${valuesTask.p001_projectManager_email}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Комментарий руководителя проекта:</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_RPComment}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Комментарий от 112:</span>
            <p className="card-info__card-value">
              {`${valuesTask.p001_112_comment}`}
            </p>
          </div>

          <div className="card-info__card-wrap-val">
            <span className="card-info__card-key">Инициатор процесса</span>
            <p className="card-info__card-value">{valuesTask.p001_processInitiator}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default CardInfo;
