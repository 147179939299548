/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable react/prop-types */
import React from 'react';

import './FormSearchField.scss';

function FormSearchField({
  buttonText,
  onSubmit,
  disabled,
  fieldType,
  fieldLabel,
  fieldName,
  fieldID,
  fieldPlaceholder,
  value,
  error,
  touched,
  onBlur,
  onChange,
  loading,
  dirty,
  valid,
}) {
  return (
    <label
      htmlFor={fieldName}
      className="form-field-search__label"
    >
      {fieldLabel}

      <div className="form-field-search__wrap">
        <input
          type={fieldType}
          name={fieldName}
          id={fieldID}
          placeholder={fieldPlaceholder}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          className={disabled || loading ? 'form-field-search__input_disabled' : 'form-field-search__input'}
          disabled={disabled || loading}
        />

        <button
          type="submit"
          aria-label={buttonText}
          onClick={onSubmit}
          className={
            disabled || loading || !valid || !dirty
              ? 'form-field-search__btn-submit_disabled'
              : 'form-field-search__btn-submit'
          }
          disabled={disabled || loading || !valid || !dirty}
        >
          {buttonText}
        </button>
      </div>

      {touched
        && error
        && <span className="form-field-search__input-err">{error}</span>}

    </label>
  );
}

export default FormSearchField;
