import React from 'react';
import { inject, observer } from 'mobx-react';

import './Board.scss';

import Task from './Task/Task';

function Board({
  userFirstName,
  userLastName,
  userGroup,

  tasks,
  taskErrMessage,
}) {
  return (
    <section className="board">
      <div className="board__head">
        <h2 className="board__title">
          {`${userFirstName} ${userLastName}`}
        </h2>
        <span className="board__subtitle">{`${userGroup}`}</span>

        <h3 className="board__path">Задачи</h3>

        {
          // ? тут можно разместить кнопки для сортировки задач
          // ? по статусу (активные/завершенные)
        }

        {tasks.length === 0
          ? (
            <span className="board__err-message">
              {taskErrMessage}
            </span>
          ) : (
            tasks.map((task) => (
              <ul className="board__tasks">
                <Task
                  task={task}
                  key={task.id}
                />
              </ul>
            ))
          )}

      </div>
    </section>
  );
}

export default inject(({
  UserStore,
  TaskStore,
}) => {
  const {
    userFirstName,
    userLastName,
    userGroup,
  } = UserStore;

  const {
    tasks,
    taskErrMessage,
  } = TaskStore;

  return {
    userFirstName,
    userLastName,
    userGroup,

    tasks,
    taskErrMessage,
  };
})(observer(Board));
