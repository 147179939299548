/* eslint-disable no-console */
/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from 'mobx';
import { encode } from 'js-base64';
import CryptoJS from 'crypto-js';

import * as UserApi from '../utils/Api/CamundaApi/UserApi';
import { secret } from '../utils/constants/constants';
import { errorMessage } from '../utils/constants/errMessages';

const {
  getUserInfo,
  getUserGroup,
} = UserApi;

export default class UserStore {
  userFirstName = '';
  userLastName = '';
  userEmail = '';
  userGroup = '';

  loggedIn = false;
  loading = false;
  errload = '';

  constructor() {
    makeAutoObservable(this);
  }

  handleLogin = async (login, password) => {
    this.loading = true;
    this.errload = '';

    const auth = encode(`${login}:${password}`);
    const authData = `Basic ${auth}`;

    await getUserInfo(login, authData)
      .then((res) => {
        const userId = CryptoJS.AES.encrypt(JSON.stringify(res.id), secret).toString();
        const token = CryptoJS.AES.encrypt(JSON.stringify(authData), secret).toString();

        localStorage.setItem('userId', userId);
        localStorage.setItem('token', token);

        this.userFirstName = res.firstName;
        this.userLastName = res.lastName;
        this.userEmail = res.email;

        this.loggedIn = true;
        this.errload = '';
      })
      .catch((err) => {
        console.log(err.message);
        this.errload = errorMessage.serverErr;
      })
      .finally(() => {
        this.loading = false;
      });
  };

  handleGetUserGroup = async (userId, token) => {
    await getUserGroup(userId, token)
      .then((res) => {
        const even = (element) => {
          if (element.name === 'camunda BPM Administrators') {
            this.userGroup = element.name;
          } else {
            this.userGroup = res.groups[0].name;
          }
        };
        res.groups.some(even);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  handleGetUserInfo = async (userId, token) => {
    await Promise.all([
      getUserInfo(userId, token),
      getUserGroup(userId, token),
    ])
      .then(([userInfo, userGroup]) => {
        this.userFirstName = userInfo.firstName;
        this.userLastName = userInfo.lastName;
        this.userEmail = userInfo.email;

        const even = (element) => {
          if (element.name === 'camunda BPM Administrators') {
            this.userGroup = element.name;
          } else {
            this.userGroup = userGroup.groups[0].name;
          }
        };
        userGroup.groups.some(even);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  handleCheckLoggedIn = async (userId, token) => {
    await getUserInfo(userId, token)
      .then(() => {
        this.loggedIn = true;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  handleLogOut = async () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('accessKey');

    this.loggedIn = false;
  };
}
