/* eslint-disable react/prop-types */
import React from 'react';

import './FormSearch.scss';

function FormSearch({
  children,
}) {
  return (
    <form
      action="#"
      className="form-search"
    >

      {children}

    </form>
  );
}

export default FormSearch;
