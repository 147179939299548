import * as constants from '../../constants/constants';

const { CAMUNDA_URL, fixPromise } = constants;

const ProcessDefinitionUrl = `${CAMUNDA_URL}/process-definition`;

// ! запрос всех процессов
// ? https://docs.camunda.org/manual/7.5/reference/rest/process-definition/get-query/
export const getListProcessDefinition = async (token) => fetch(`${ProcessDefinitionUrl}`, {
  method: 'GET',
  headers: {
    Authorization: token,
    'Content-Type': 'application/json',
  },
}).then((res) => fixPromise(res));

// ! старт процесса
// ? https://docs.camunda.org/manual/7.5/reference/rest/process-definition/post-start-process-instance/
export const startProcessInstance = async (token, processID) => fetch(`${ProcessDefinitionUrl}/${processID}/start`, {
  method: 'POST',
  headers: {
    Authorization: token,
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({}),
}).then((res) => fixPromise(res));