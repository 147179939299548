/* eslint-disable no-undef */
/* eslint-disable no-console */
export const BASE_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_DEV_BASE_URL
  : process.env.REACT_APP_PROD_BASE_URL;

export const CAMUNDA_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_DEV_CAMUNDA_URL
  : process.env.REACT_APP_PROD_CAMUNDA_URL;

export const secret = process.env.REACT_APP_SECRET_KEY;
export const POLATI_API = process.env.REACT_APP_POLATI_API;
export const POLATI_TOKEN = process.env.REACT_APP_POLATI_TOKEN;
export const POLATI2_URL = process.env.REACT_APP_POLATI2;

// фиксация промиса
export const fixPromise = (res) => (
  res.ok ? res.json()
    : Promise.reject(new Error(`Произошла ошибка ${res.status}: ${res.statusText}`))
);

// фиксация, если запрос ничего не возвращает
export const fixEmptyAnswer = (res) => (
  res.ok
    ? console.log(`Запрос выполнен успешно. Статус: ${res.status}`)
    : Promise.reject(new Error(`Произошла ошибка ${res.status}: ${res.statusText}`))
);

/* == навигация == */
export const path = {
  main: '/',
  login: '/login',
  dismissal: '/dismissal',
  formInitiation: '/initiation',
  formApprovalDecision: '/approval-decision',
  formCollectInfo: '/collect-info',
  formDismissalDecision: '/decision',
};

/* == стадии процесса (таски) == */
export const stageProcess = {
  formInitiation: 'p001_formInitiation',
  formApprovalDecision: 'p001_formApprovalDecision',
  formCollectInfo: 'p001_formCollectInfo',
  formDismissalDecision: 'p001_formDismissalDecision',
};

/* == Тип увольнения == */
// ! поле удалено
export const typesDismissal = [
  {
    name: 'Собственное желание',
    id: 'typeDismissal_id_001',
  },
  {
    name: 'Решение компании',
    id: 'typeDismissal_id_002',
  },
];

/* Причины увольнения */
export const reasonsDismissal = [
  {
    name: 'Алкоголь',
    id: 'reasonDismissal_id_001',
  },
  {
    name: 'Наркотики',
    id: 'reasonDismissal_id_002',
  },
  {
    name: 'Драка',
    id: 'reasonDismissal_id_003',
  },
  {
    name: 'Бунт организатор',
    id: 'reasonDismissal_id_004',
  },
  {
    name: 'Бунт участник',
    id: 'reasonDismissal_id_005',
  },
  {
    name: 'Семейные обстоятельства',
    id: 'reasonDismissal_id_006',
  },
  {
    name: 'Проблемы со здоровьем',
    id: 'reasonDismissal_id_007',
  },
  {
    name: 'Производственная травма',
    id: 'reasonDismissal_id_008',
  },
  {
    name: 'Сбежал с объекта/карантина',
    id: 'reasonDismissal_id_009',
  },
  {
    name: 'Нашел другую работу',
    id: 'reasonDismissal_id_010',
  },
  {
    name: 'Ушел к конкурентам (Лесавик, Трестстрой и т.д.)',
    id: 'reasonDismissal_id_011',
  },
  {
    name: 'Ленивый работник',
    id: 'reasonDismissal_id_012',
  },
  {
    name: 'Прогульщик / Обманщик',
    id: 'reasonDismissal_id_013',
  },
  {
    name: 'Поддельный больничный',
    id: 'reasonDismissal_id_014',
  },
  {
    name: 'Пропал без вести',
    id: 'reasonDismissal_id_015',
  },
  {
    name: 'Несоответствие должности, отказ переходить на другую должность',
    id: 'reasonDismissal_id_016',
  },
  {
    name: 'Отказ от перевода на другой объект',
    id: 'reasonDismissal_id_017',
  },
  {
    name: 'В розыске',
    id: 'reasonDismissal_id_018',
  },
  {
    name: 'Тяжелая работа. Не готов к сложному физическому труду',
    id: 'reasonDismissal_id_019',
  },
  {
    name: 'Тяжелый климат. Не готов к тяжелым погодным условиям',
    id: 'reasonDismissal_id_020',
  },
  {
    name: 'Длинная вахта. Не готов длительно работать вдали от дома',
    id: 'reasonDismissal_id_021',
  },
  {
    name: 'Конфликт с мастером',
    id: 'reasonDismissal_id_022',
  },
  {
    name: 'Конфликт с РП',
    id: 'reasonDismissal_id_023',
  },
  {
    name: 'Не устраивает размер зарплаты',
    id: 'reasonDismissal_id_024',
  },
  {
    name: 'Техническое увольнение. Сезонное',
    id: 'reasonDismissal_id_025',
  },
];
