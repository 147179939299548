import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { inject, observer } from 'mobx-react';
import CryptoJS from 'crypto-js';

import Content from '../Content';
import CardInfo from '../../../../utils/ui/CardInfo/CardInfo';
import Form from '../../../../utils/ui/Form/Form';
import FormField from '../../../../utils/ui/FormField/FormField';
import FormSelect from '../../../../utils/ui/FormSelect/FormSelect';
import FormSelectOption from '../../../../utils/ui/FormSelect/FormSelectOption/FormSelectOption';

import * as validation from './validation';
import { path, reasonsDismissal, secret } from '../../../../utils/constants/constants';

const {
  validationsSchema,
} = validation;

function FormCollectInfo({
  task,
  taskValues,
  handleCompleteTask,

  loading,
  errload,
}) {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      // ? переменные из других тасков
      p001_processName: taskValues.p001_processName,
      p001_employee_fullName: taskValues.p001_employee_fullName,
      p001_employee_position: taskValues.p001_employee_position,
      p001_employee_TN: taskValues.p001_employee_TN,
      p001_employee_phone: taskValues.p001_employee_phone,
      p001_employee_object: taskValues.p001_employee_object,
      p001_employee_statusOnTheObject: taskValues.p001_employee_statusOnTheObject,
      p001_dateDismissal: taskValues.p001_dateDismissal,
      p001_employeeManager_fullName: taskValues.p001_employeeManager_fullName,
      p001_employeeManager_phone: taskValues.p001_employeeManager_phone,
      p001_initiatorDismissal_fullName: taskValues.p001_initiatorDismissal_fullName,
      p001_initiatorDismissal_phone: taskValues.p001_initiatorDismissal_phone,
      p001_projectManager_fullName: taskValues.p001_projectManager_fullName,
      p001_projectManager_phone: taskValues.p001_projectManager_phone,
      p001_projectManager_email: taskValues.p001_projectManager_email,
      p001_projectManager_id: taskValues.p001_projectManager_id,

      p001_blackListDecision: taskValues.p001_blackListDecision,
      p001_grayListDecision: taskValues.p001_grayListDecision,
      p001_RPComment: taskValues.p001_RPComment,
      p001_approveCheck: taskValues.p001_approveCheck,

      // ! переменные этого таска
      p001_initiatorDismissal_comment: taskValues.p001_initiatorDismissal_comment,
      p001_employeeManager_comment: taskValues.p001_employeeManager_comment,
      p001_reasonDismissal: taskValues.p001_reasonDismissal,
      p001_employee_comment: taskValues.p001_employee_comment,
      p001_112_comment: taskValues.p001_112_comment,
      p001_eventType: taskValues.p001_eventType,

      // ? переменные из других тасков
      p001_dismissalDecision: taskValues.p001_dismissalDecision,
      p001_proceedDecision: taskValues.p001_proceedDecision,
    },
    validationSchema: validationsSchema,
    onSubmit: async (values) => {
      const getToken = localStorage.getItem('token');
      const bytesToken = CryptoJS.AES.decrypt(getToken, secret);
      const token = JSON.parse(bytesToken.toString(CryptoJS.enc.Utf8));

      await handleCompleteTask(token, values, task)
        .then(() => navigate(path.main))
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err.message)); // вывести ошибку в форме
    },
  });

  useEffect(() => {
    if (taskValues) {
      formik.setValues(taskValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskValues]);

  return (
    <Content
      titleSection="Заполнить форму сбора информации по увольнению"
    >

      <div className="card-info">

        <CardInfo
          valuesTask={taskValues}
        />

        <Form
          buttonText={loading ? 'Сохранение...' : 'Отправить'}
          onSubmit={formik.handleSubmit}
          disabled={!formik.isValid}
          loading={loading}
          error={errload}
        >
          <FormField
            fieldType="text"
            fieldLabel="Комментарий инициатора увольнения"
            fieldName="p001_initiatorDismissal_comment"
            fieldID="FormCollectInfo_p001_initiatorDismissal_comment"
            fieldPlaceholder="Причина увольнения"
            value={formik.values.p001_initiatorDismissal_comment}
            error={formik.errors.p001_initiatorDismissal_comment}
            touched={formik.touched.p001_initiatorDismissal_comment}
            onBlur={formik.handleBlur}
            onChange={(evt) => {
              formik.handleChange(evt);
              formik.setFieldTouched('p001_initiatorDismissal_comment');
            }}
            disabled={!loading ? '' : 'disabled'}
          />

          <FormField
            fieldType="text"
            fieldLabel="Комментарий непосредственного руководителя"
            fieldName="p001_employeeManager_comment"
            fieldID="FormCollectInfo_p001_employeeManager_comment"
            fieldPlaceholder="Причина увольнения"
            value={formik.values.p001_employeeManager_comment}
            error={formik.errors.p001_employeeManager_comment}
            touched={formik.touched.p001_employeeManager_comment}
            onBlur={formik.handleBlur}
            onChange={(evt) => {
              formik.handleChange(evt);
              formik.setFieldTouched('p001_employeeManager_comment');
            }}
            disabled={!loading ? '' : 'disabled'}
          />

          <FormSelect
            fieldLabel="Причина увольнения"
            fieldName="p001_reasonDismissal"
            fieldID="FormCollectInfo_p001_reasonDismissal"
            error={formik.errors.p001_reasonDismissal}
            touched={formik.touched.p001_reasonDismissal}
            selectValue={formik.values.p001_reasonDismissal}
            onBlur={formik.handleBlur}
            onChange={(evt) => {
              formik.handleChange(evt);
              formik.setFieldTouched('p001_reasonDismissal');
            }}
            disabled={!loading ? '' : 'disabled'}
          >
            <FormSelectOption
              optionValue=""
              optionText="Выберете значение.."
              selected
              disabled
            />
            {reasonsDismissal.map((reasonDismissal) => (
              <FormSelectOption
                reason={reasonDismissal}
                key={reasonDismissal.id}
                optionValue={reasonDismissal.name}
                optionText={reasonDismissal.name}
              />
            ))}
          </FormSelect>

          <FormField
            fieldType="text"
            fieldLabel="Комментарий от сотрудника"
            fieldName="p001_employee_comment"
            fieldID="FormCollectInfo_p001_employee_comment"
            fieldPlaceholder="Комментарий от работника"
            value={formik.values.p001_employee_comment}
            error={formik.errors.p001_employee_comment}
            touched={formik.touched.p001_employee_comment}
            onBlur={formik.handleBlur}
            onChange={(evt) => {
              formik.handleChange(evt);
              formik.setFieldTouched('p001_employee_comment');
            }}
            disabled={!loading ? '' : 'disabled'}
          />

          <FormField
            fieldType="text"
            fieldLabel="Комментарий от 112"
            fieldName="p001_112_comment"
            fieldID="FormCollectInfo_p001_112_comment"
            fieldPlaceholder="Комментарий от 112"
            value={formik.values.p001_112_comment}
            error={formik.errors.p001_112_comment}
            touched={formik.touched.p001_112_comment}
            onBlur={formik.handleBlur}
            onChange={(evt) => {
              formik.handleChange(evt);
              formik.setFieldTouched('p001_112_comment');
            }}
            disabled={!loading ? '' : 'disabled'}
          />

        </Form>
      </div>
    </Content>
  );
}

export default inject(({
  TaskStore,
}) => {
  const {
    task,
    taskValues,
    handleCompleteTask,

    loading,
    errload,
  } = TaskStore;

  return {
    task,
    taskValues,
    handleCompleteTask,
    loading,
    errload,
  };
})(observer(FormCollectInfo));
