import React from 'react';
import { useLocation } from 'react-router-dom';

import './Content.scss';

import { path } from '../../../utils/constants/constants';

function Content({
  children,
  titleSection,
}) {
  const location = useLocation();
  return (
    <section
      className={
        location.pathname === path.login ? 'content content_login' : 'content'
      }
    >
      <h2 className="content__title">{titleSection}</h2>

      {children}

    </section>
  );
}

export default Content;
