import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { inject, observer } from 'mobx-react';
import CryptoJS from 'crypto-js';

import Content from '../Content';
import CardInfo from '../../../../utils/ui/CardInfo/CardInfo';
import Form from '../../../../utils/ui/Form/Form';
import FormSelect from '../../../../utils/ui/FormSelect/FormSelect';
import FormSelectOption from '../../../../utils/ui/FormSelect/FormSelectOption/FormSelectOption';
import FormCheckbox from '../../../../utils/ui/FormCheckbox/FormCheckbox';
import DismissalDecisionModal from '../../../Modals/DismissalDecisionModal/DismissalDecisionModal';

import * as validation from './validation';
import { path, secret } from '../../../../utils/constants/constants';

const {
  validationsSchema,
} = validation;

function FormDismissalDecision({
  task,
  taskValues,

  handleCompleteTask,
  handleSavedValuesTask,

  loading,
  errload,
}) {
  const navigate = useNavigate();

  const [isModalDismissalDecision, setModalDismissalDecision] = useState(false);
  const [isWork, setWork] = useState(false);

  // обработчик ОТКРЫТИЯ модалки по решению об увольнении
  const handleOpenModalDismissalDecision = async (data) => {
    await handleSavedValuesTask(data);

    setModalDismissalDecision(true);
  };

  // обработчик ЗАКРЫТИЯ модалки по решению об увольнении
  const handleCloseModalDismissalDecision = () => {
    setModalDismissalDecision(false);
    navigate(path.main);
  };

  const formik = useFormik({
    initialValues: {
      // ? переменные из других тасков
      p001_processName: taskValues.p001_processName,
      p001_employee_fullName: taskValues.p001_employee_fullName,
      p001_employee_position: taskValues.p001_employee_position,
      p001_employee_TN: taskValues.p001_employee_TN,
      p001_employee_phone: taskValues.p001_employee_phone,
      p001_employee_object: taskValues.p001_employee_object,
      p001_employee_statusOnTheObject: taskValues.p001_employee_statusOnTheObject,
      p001_dateDismissal: taskValues.p001_dateDismissal,
      p001_employeeManager_fullName: taskValues.p001_employeeManager_fullName,
      p001_employeeManager_phone: taskValues.p001_employeeManager_phone,
      p001_initiatorDismissal_fullName: taskValues.p001_initiatorDismissal_fullName,
      p001_initiatorDismissal_phone: taskValues.p001_initiatorDismissal_phone,
      p001_projectManager_fullName: taskValues.p001_projectManager_fullName,
      p001_projectManager_phone: taskValues.p001_projectManager_phone,
      p001_projectManager_email: taskValues.p001_projectManager_email,
      p001_projectManager_id: taskValues.p001_projectManager_id,
      p001_eventType: 'globalSendMail',

      p001_RPComment: taskValues.p001_RPComment,
      p001_approveCheck: taskValues.p001_approveCheck,

      p001_initiatorDismissal_comment: taskValues.p001_initiatorDismissal_comment,
      p001_employeeManager_comment: taskValues.p001_employeeManager_comment,
      p001_reasonDismissal: taskValues.p001_reasonDismissal,
      p001_employee_comment: taskValues.p001_employee_comment,
      p001_112_comment: taskValues.p001_112_comment,

      // ! переменные этого таска
      p001_blackListDecision: taskValues.p001_blackListDecision,
      p001_grayListDecision: taskValues.p001_grayListDecision,
      p001_dismissalDecision: taskValues.p001_dismissalDecision,
      p001_proceedDecision: taskValues.p001_proceedDecision,
    },
    validationSchema: validationsSchema,
    onSubmit: async (values) => {
      const getToken = localStorage.getItem('token');
      const bytesToken = CryptoJS.AES.decrypt(getToken, secret);
      const token = JSON.parse(bytesToken.toString(CryptoJS.enc.Utf8));

      await handleCompleteTask(token, values, task)
        .then(() => handleOpenModalDismissalDecision(values))
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err.message)); // вывести ошибку в форме
    },
  });

  useEffect(() => {
    if (formik.values.p001_dismissalDecision === 'Оставляем') {
      setWork(true);
    }

    if (formik.values.p001_dismissalDecision === 'Уволить') {
      setWork(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.p001_dismissalDecision, isWork]);

  return (
    <Content
      titleSection="Заполнить форму сбора информации по увольнению"
    >
      <div className="card-info">

        <CardInfo
          valuesTask={taskValues}
        />

        <Form
          buttonText={loading ? 'Сохранение...' : 'Отправить'}
          onSubmit={formik.handleSubmit}
          disabled={!formik.isValid}
          loading={loading}
          error={errload}
        >

          <FormSelect
            fieldLabel="Решение о продолжении работы"
            fieldName="p001_dismissalDecision"
            fieldID="FormDismissalDecision_p001_dismissalDecision"
            error={formik.errors.p001_dismissalDecision}
            touched={formik.touched.p001_dismissalDecision}
            selectValue={formik.values.p001_dismissalDecision}
            onBlur={formik.handleBlur}
            onChange={(evt) => {
              formik.handleChange(evt);
              formik.setFieldTouched('p001_dismissalDecision');
            }}
            disabled={!loading ? '' : 'disabled'}
          >
            <FormSelectOption
              optionValue=""
              optionText="Выберете значение.."
              selected
              disabled
            />
            <FormSelectOption
              optionValue="Уволить"
              optionText="Уволить"
            />
            <FormSelectOption
              optionValue="Оставляем"
              optionText="Оставить"
            />
          </FormSelect>

          <FormCheckbox
            fieldLabel="Черный список"
            fieldName="p001_blackListDecision"
            fieldID="FormDismissalDecision_p001_blackListDecision"
            error={formik.errors.p001_blackListDecision}
            touched={formik.touched.p001_blackListDecision}
            value={formik.values.p001_blackListDecision}
            onBlur={formik.handleBlur}
            onChange={(evt) => {
              formik.handleChange(evt);
              formik.setFieldTouched('p001_blackListDecision');
            }}
            checked={formik.values.p001_blackListDecision}
            disabled={!loading ? '' : 'disabled'}
          />

          <FormCheckbox
            fieldLabel="Серый список"
            fieldName="p001_grayListDecision"
            fieldID="FormDismissalDecision_p001_grayListDecision"
            error={formik.errors.p001_grayListDecision}
            touched={formik.touched.p001_grayListDecision}
            value={formik.values.p001_grayListDecision}
            onBlur={formik.handleBlur}
            onChange={(evt) => {
              formik.handleChange(evt);
              formik.setFieldTouched('p001_grayListDecision');
            }}
            checked={formik.values.p001_grayListDecision}
            disabled={!loading ? '' : 'disabled'}
          />

          {isWork ? (
            <FormSelect
              fieldLabel="Решение об увольнении"
              fieldName="p001_proceedDecision"
              fieldID="FormDismissalDecision_p001_proceedDecision"
              error={formik.errors.p001_proceedDecision}
              touched={formik.touched.p001_proceedDecision}
              selectValue={formik.values.p001_proceedDecision}
              onBlur={formik.handleBlur}
              onChange={(evt) => {
                formik.handleChange(evt);
                formik.setFieldTouched('p001_proceedDecision');
              }}
              disabled={!loading ? '' : 'disabled'}
            >
              <FormSelectOption
                optionValue=""
                optionText="Выберете значение.."
                selected
                disabled
              />
              <FormSelectOption
                optionValue="Отпуск"
                optionText="Отпуск"
              />
              <FormSelectOption
                optionValue="Перевод"
                optionText="Перевод"
              />
              <FormSelectOption
                optionValue="продолжает работать"
                optionText="Продолжает работать"
              />
            </FormSelect>
          ) : (
            <></>
          )}

        </Form>
      </div>

      <DismissalDecisionModal
        isModalDismissalDecision={isModalDismissalDecision}
        values={taskValues}
        handleCloseModalDismissalDecision={handleCloseModalDismissalDecision}
      />

    </Content>
  );
}

export default inject(({
  TaskStore,
}) => {
  const {
    task,
    taskValues,
    handleCompleteTask,
    handleSavedValuesTask,

    loading,
    errload,
  } = TaskStore;

  return {
    task,
    taskValues,
    handleCompleteTask,
    handleSavedValuesTask,
    loading,
    errload,
  };
})(observer(FormDismissalDecision));
