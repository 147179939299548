import * as yup from 'yup';

import * as validMessages from '../../../../utils/constants/validMessages';

const {
  validMessage,
} = validMessages;

// eslint-disable-next-line import/prefer-default-export
export const validationsSchema = yup.object().shape({
  login: yup.string()
    .typeError(validMessage.typeStringErr)
    .matches(/[A-Za-z0-9_-]+/, { message: validMessage.latinNumErr })
    .required(validMessage.requiredErr),
  password: yup.string()
    .typeError(validMessage.typeStringErr)
    .min(8, 'Должно быть не менее 8 символов')
    .required(validMessage.requiredErr),
});
