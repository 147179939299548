/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from 'mobx';

import * as UserApi from '../utils/Api/CamundaApi/UserApi';

const {
  getUserGroup,
} = UserApi;

export default class GroupStore {
  groupPolRPUsers = [];

  constructor() {
    makeAutoObservable(this);
  }

  handleGetUserGroupRPUsers = async (/* token */) => {
    const userId = 'dmitriev';
    const token = 'Basic bWtkaXJkZXY6RHJlc2RlbjIwMTk=';

    await getUserGroup(userId, token)
      .then((res) => {
        this.groupPolRPUsers = res.groupUsers;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}
