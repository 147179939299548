import * as yup from 'yup';

import * as validMessages from '../../../../utils/constants/validMessages';

const {
  validMessage,
} = validMessages;

// eslint-disable-next-line import/prefer-default-export
export const validationsSchema = yup.object().shape({
  p001_initiatorDismissal_comment: yup.string()
    .typeError(validMessage.typeStringErr)
    .min(5, 'Должно быть не менее 5 символов')
    .matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
    .required(validMessage.requiredErr),
  p001_employeeManager_comment: yup.string()
    .typeError(validMessage.typeStringErr)
    .min(5, 'Должно быть не менее 5 символов')
    .matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
    .required(validMessage.requiredErr),
  p001_reasonDismissal: yup.string()
    .typeError('Значение должно быть выбрано')
    .matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
    .required(validMessage.requiredErr),
  p001_employee_comment: yup.string()
    .typeError(validMessage.typeStringErr)
    .min(5, 'Должно быть не менее 5 символов')
    .matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
    .required(validMessage.requiredErr),
  p001_112_comment: yup.string()
    .typeError(validMessage.typeStringErr)
    .min(5, 'Должно быть не менее 5 символов')
    .matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
    .required(validMessage.requiredErr),
});
