import * as constants from '../../constants/constants';

const {
  CAMUNDA_URL, fixPromise,
} = constants;

const UserUrl = `${CAMUNDA_URL}/user`;

// ! запрос пользователя по id (login)
// ? https://docs.camunda.org/manual/7.16/reference/rest/user/get/
export const getUserInfo = async (userId, token) => fetch(`${UserUrl}/${userId}/profile`, {
  method: 'GET',
  headers: {
    Authorization: token,
    'Content-Type': 'application/json',
  },
}).then((res) => fixPromise(res));

// пока тут, потом перенести в др файл
// ! идентифицировать группы юзера
export const getUserGroup = async (userId, token) => fetch(`${CAMUNDA_URL}/identity/groups?userId=${userId}`, {
  method: 'GET',
  headers: {
    Authorization: token,
    'Content-Type': 'application/json',
  },
}).then((res) => fixPromise(res));
