import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import CryptoJS from 'crypto-js';
import queryString from 'query-string';

import './App.scss';

import Header from '../Header/Header';
import Main from '../Main/Main';

import { secret } from '../../utils/constants/constants';

function App({
  loggedIn,
  handleGetUserInfo,
  handleCheckLoggedIn,

  handleListProcesses,

  handleGetUserGroupRPUsers,

  handleListTasks,

  handleTokenAuth,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const checkToken = async () => {
    const token = localStorage.getItem('token');

    if (token) {
      await handleListProcesses(token);
      await handleListTasks(token);
      await handleGetUserGroupRPUsers();
    }
  };

  useEffect(() => {
    if (location.hash) {
      const parsedHash = queryString.parse(location.hash);
      handleTokenAuth(parsedHash);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getToken = localStorage.getItem('token');
    const getUserId = localStorage.getItem('userId');

    if (getToken && getUserId) {
      const bytesToken = CryptoJS.AES.decrypt(getToken, secret);
      const bytesUserId = CryptoJS.AES.decrypt(getUserId, secret);

      const token = JSON.parse(bytesToken.toString(CryptoJS.enc.Utf8));
      const userId = JSON.parse(bytesUserId.toString(CryptoJS.enc.Utf8));

      if (token) {
        handleListProcesses(token);
        handleListTasks(token);
        handleGetUserGroupRPUsers();

        navigate(location.pathname);
      }

      if (token) {
        handleCheckLoggedIn(userId, token);
      }

      if (loggedIn) {
        handleGetUserInfo(userId, token);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    const getToken = localStorage.getItem('token');

    if (getToken) {
      const bytesToken = CryptoJS.AES.decrypt(getToken, secret);
      const token = JSON.parse(bytesToken.toString(CryptoJS.enc.Utf8));

      if (token) {
        navigate(location.pathname);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app">
      <Header />
      <Main />
    </div>
  );
}

export default inject(({
  UserStore,
  ProcessStore,
  GroupStore,
  TaskStore,
  StaffStore,
}) => {
  const {
    loggedIn,
    handleGetUserInfo,
    handleCheckLoggedIn,
  } = UserStore;

  const {
    handleListProcesses,
  } = ProcessStore;

  const {
    handleGetUserGroupRPUsers,
  } = GroupStore;

  const {
    handleListTasks,
  } = TaskStore;

  const {
    handleTokenAuth,
  } = StaffStore;

  return {
    loggedIn,
    handleGetUserInfo,
    handleCheckLoggedIn,

    handleListProcesses,

    handleGetUserGroupRPUsers,

    handleListTasks,

    handleTokenAuth,
  };
})(observer(App));
