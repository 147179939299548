import React from 'react';

import './Process.scss';

import { inject, observer } from 'mobx-react';
import CryptoJS from 'crypto-js';

import { secret } from '../../../../../utils/constants/constants';

function Process({
  process,
  handleStartProcess,
  handleListTasks,
}) {
  const hancleClickStartProcess = async () => {
    const getToken = localStorage.getItem('token');
    const bytesToken = CryptoJS.AES.decrypt(getToken, secret);
    const token = JSON.parse(bytesToken.toString(CryptoJS.enc.Utf8));

    await handleStartProcess(token, process.id);
    await handleListTasks(token);
  };

  return (
    <li
      className="process"
    >
      <button
        type="button"
        className="process__btn"
        onClick={hancleClickStartProcess}
      >
        {process.name}
      </button>
    </li>
  );
}

export default inject(({
  ProcessStore,
  TaskStore,
}) => {
  const { handleStartProcess } = ProcessStore;

  const {
    handleListTasks,
  } = TaskStore;

  return {
    handleStartProcess,

    handleListTasks,
  };
})(observer(Process));
