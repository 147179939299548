import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line no-unused-vars
import { toJS } from 'mobx';
import CryptoJS from 'crypto-js';

import './Task.scss';

import { getTaskFormVariables } from '../../../../utils/Api/CamundaApi/TaskApi';
import { path, stageProcess, secret } from '../../../../utils/constants/constants';

function Task({
  task,

  handleSavedTask,
  handleSavedValuesTask,
}) {
  const navigate = useNavigate();

  const [taskVariables, setTaskVariables] = useState({});

  const handleRedirectByTaskStatus = async (obj) => {
    await handleSavedTask(obj);

    if (obj.taskDefinitionKey === stageProcess.formInitiation) {
      navigate(path.formInitiation);
    } else if (obj.taskDefinitionKey === stageProcess.formApprovalDecision) {
      navigate(path.formApprovalDecision);
    } else if (obj.taskDefinitionKey === stageProcess.formCollectInfo) {
      navigate(path.formCollectInfo);
    } else if (obj.taskDefinitionKey === stageProcess.formDismissalDecision) {
      navigate(path.formDismissalDecision);
    } else {
      navigate(path.main); // перенаправить на главную стр
    }
  };

  const handleTaskFormVariables = async (token, taskID) => {
    await getTaskFormVariables(token, taskID)
      .then((res) => {
        setTaskVariables({
          p001_processName: res.p001_processName.value,
          p001_employee_fullName: res.p001_employee_fullName.value,
          p001_employee_position: res.p001_employee_position.value,
          p001_employee_TN: res.p001_employee_TN.value,
          p001_employee_phone: res.p001_employee_phone.value,
          p001_employee_object: res.p001_employee_object.value,
          p001_employee_statusOnTheObject: res.p001_employee_statusOnTheObject.value,
          p001_reasonDismissal: res.p001_reasonDismissal.value,
          p001_dateDismissal: res.p001_dateDismissal.value,
          p001_employeeManager_fullName: res.p001_employeeManager_fullName.value,
          p001_employeeManager_phone: res.p001_employeeManager_phone.value,
          p001_initiatorDismissal_fullName: res.p001_initiatorDismissal_fullName.value,
          p001_initiatorDismissal_phone: res.p001_initiatorDismissal_phone.value,
          p001_projectManager_fullName: res.p001_projectManager_fullName.value,
          p001_projectManager_phone: res.p001_projectManager_phone.value,
          p001_projectManager_email: res.p001_projectManager_email.value,
          p001_projectManager_id: res.p001_projectManager_id.value,
          p001_processInitiator: res.p001_processInitiator.value,
          p001_eventType: res.p001_eventType.value,

          p001_blackListDecision: res.p001_blackListDecision.value,
          p001_grayListDecision: res.p001_grayListDecision.value,
          p001_RPComment: res.p001_RPComment.value,
          p001_approveCheck: res.p001_approveCheck.value,

          p001_initiatorDismissal_comment: res.p001_initiatorDismissal_comment.value,
          p001_employeeManager_comment: res.p001_employeeManager_comment.value,
          p001_employee_comment: res.p001_employee_comment.value,
          p001_112_comment: res.p001_112_comment.value,

          p001_dismissalDecision: res.p001_dismissalDecision.value,
          p001_proceedDecision: res.p001_proceedDecision.value,
        });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(`Не удалось получить данные задачи: ${err.message}`));
  };

  const handleClickTask = async () => {
    // console.log(toJS(taskVariables));
    // console.log(toJS(task));

    await handleSavedValuesTask(taskVariables);
    await handleRedirectByTaskStatus(task);
  };

  useEffect(() => {
    const getToken = localStorage.getItem('token');
    const bytesToken = CryptoJS.AES.decrypt(getToken, secret);
    const token = JSON.parse(bytesToken.toString(CryptoJS.enc.Utf8));

    handleTaskFormVariables(token, task.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  return (
    <li
      className="task"
    >
      <button
        type="button"
        className="task__btn"
        onClick={handleClickTask}
      >
        {
          taskVariables.p001_processName
            ? (<h4 className="task__title">{taskVariables.p001_processName}</h4>)
            : (<h4 className="task__title">Увольнение сотрудника</h4>)
        }

        <div className="task__wrap-val">
          <span className="task__key">Статус задачи</span>
          <p className="task__value">{task.name}</p>
        </div>

        {
          task.name === 'Заполнить форму инициации'
            ? (
              <div className="task__wrap-val">
                <p className="task__value task__value_err">Нет данных, заполните форму</p>
              </div>
            ) : (
              <>
                <div className="task__wrap-val">
                  <span className="task__key">Сотрудник</span>
                  <p className="task__value">{taskVariables.p001_employee_fullName}</p>
                </div>

                <div className="task__wrap-val">
                  <span className="task__key">Телефон сотрудника</span>
                  <p className="task__value">{taskVariables.p001_employee_phone}</p>
                </div>

                <div className="task__wrap-val">
                  <span className="task__key">Дата увольнения</span>
                  <p className="task__value">{taskVariables.p001_dateDismissal}</p>
                </div>
              </>
            )
        }

      </button>
    </li>
  );
}

export default inject(({
  TaskStore,
}) => {
  const {
    handleSavedTask,
    handleSavedValuesTask,
  } = TaskStore;

  return {
    handleSavedTask,
    handleSavedValuesTask,
  };
})(observer(Task));
