import * as yup from 'yup';

import * as validMessages from '../../../../utils/constants/validMessages';

const {
  // eslint-disable-next-line no-unused-vars
  validMessage,
} = validMessages;

// eslint-disable-next-line import/prefer-default-export
export const validationsSchema = yup.object().shape({
  /*
p001_dismissalDecisions: yup.string()
.typeError('Значение должно быть выбрано')
.matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
.required(validMessage.requiredErr),
/*
p001_proceedDecision: yup.string()
.typeError('Значение должно быть выбрано')
.matches(/[А-Яа-я0-9_-]+/, { message: validMessage.cyrillicNumErr })
.required(validMessage.requiredErr),
*/
});
