import React from 'react';

import './Login.scss';

import { useFormik } from 'formik';
import { inject, observer } from 'mobx-react';

import Content from '../Content';
import Form from '../../../../utils/ui/Form/Form';
import FormField from '../../../../utils/ui/FormField/FormField';

import * as validation from './validation';

import { BASE_URL } from '../../../../utils/constants/constants';

const {
  validationsSchema,
} = validation;

function Login({
  loading,
  errload,
  handleLogin,
}) {
  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: validationsSchema,
    onSubmit: async (values) => {
      await handleLogin(values.login, values.password);

      const a = document.createElement('a');
      a.href = `https://polati4.itscrm.ru/?referrer=${BASE_URL}`;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
    },
  });

  return (
    <Content
      titleSection="Вход"
    >
      <Form
        buttonText={loading ? 'Вход...' : 'Войти'}
        onSubmit={formik.handleSubmit}
        disabled={!formik.isValid || !formik.dirty}
        loading={loading}
        error={errload}
      >
        <FormField
          fieldType="text"
          fieldLabel="Логин"
          fieldName="login"
          fieldID="FormFeedback_login"
          fieldPlaceholder="Логин"
          value={formik.values.login}
          error={formik.errors.login}
          touched={formik.touched.login}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('login');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

        <FormField
          fieldType="password"
          fieldLabel="Пароль"
          fieldName="password"
          fieldID="FormFeedback_password"
          fieldPlaceholder="Пароль"
          value={formik.values.password}
          error={formik.errors.password}
          touched={formik.touched.password}
          onBlur={formik.handleBlur}
          onChange={(evt) => {
            formik.handleChange(evt);
            formik.setFieldTouched('password');
          }}
          disabled={!loading ? '' : 'disabled'}
        />

      </Form>
    </Content>
  );
}

// eslint-disable-next-line no-unused-vars
export default inject(({ UserStore, StaffStore }) => {
  const {
    loading,
    errload,
    handleLogin,
  } = UserStore;

  return {
    loading,
    errload,
    handleLogin,
  };
})(observer(Login));
