/* eslint-disable no-console */
/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from 'mobx';
// eslint-disable-next-line no-unused-vars
import queryString from 'query-string';

import * as StaffApi from '../utils/Api/PolatiApi/StaffApi';
import { errorMessage } from '../utils/constants/errMessages';
import { BASE_URL } from '../utils/constants/constants';

const {
  getListEvents,
  getStaffByColOne,
  getAccessKey,
} = StaffApi;

const handleCheckToken = (err) => {
  console.log(err.message);

  const a = document.createElement('a');
  a.href = `https://polati4.itscrm.ru/?referrer=${BASE_URL}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default class StaffStore {
  employeeTN = '';
  employeeFullName = '';
  employeePosition = '';
  employeePhone = '';
  employeeObject = '';
  employeeStatusOnTheObject = '';
  employeeBlackList = false;
  employeeGrayList = false;

  employeeManagerFullName = '';
  employeeManagerPhone = '';

  initiatorDismissalFullName = '';
  initiatorDismissalPhone = '';

  projectManagerFullName = '';
  projectManagerPhone = '';
  projectManagerEmail = '';
  projectManagerID = '';

  loading = false;
  errload = '';

  tokenAuth = '';
  accessKey = '';

  constructor() {
    makeAutoObservable(this);
  }

  handleTokenAuth = async (parsedHash) => {
    if (parsedHash.code !== undefined) {
      this.tokenAuth = parsedHash.code;
      const token = parsedHash.code;

      await getAccessKey(token)
        .then((res) => {
          this.accessKey = res.value;
          localStorage.setItem('accessKey', res.value);

          if (res.code === 403) {
            handleCheckToken(res);
          }
        })
        .catch((err) => {
          if (err) {
            handleCheckToken(err);
          } else {
            console.log(err.message);
          }
        });
    }
  };

  handleResetState = () => {
    this.employeeTN = '';
    this.employeeFullName = '';
    this.employeePosition = '';
    this.employeePhone = '';
    this.employeeObject = '';
    this.employeeStatusOnTheObject = '';
  };

  handleGetEmployeeByTN = async (accessKey, TN) => {
    this.loading = true;
    this.errload = '';

    this.employeeTN = '';
    this.employeeFullName = '';
    this.employeePosition = '';
    this.employeePhone = '';
    this.employeeObject = '';
    this.employeeStatusOnTheObject = '';
    this.employeeBlackList = false;
    this.employeeGrayList = false;

    const errDownload = 'Не удалось загрузить данные';

    await Promise.all([
      getListEvents(accessKey, TN),
      getStaffByColOne(accessKey, TN),
    ])
      .then(([ListEvents, StaffByColOne]) => {
        if (ListEvents.result[0].patronymic === null) {
          this.employeeFullName = `${ListEvents.result[0].surname} ${ListEvents.result[0].name}`;
        } else {
          this.employeeFullName = `${ListEvents.result[0].surname} ${ListEvents.result[0].name} ${ListEvents.result[0].patronymic}`;
        }

        if (ListEvents.result[0].current_event) {
          if (ListEvents.result[0].current_event[0].position) {
            const arr = Object.values(ListEvents.result[0].current_event[0].position);
            this.employeePosition = arr[0].position_data.title;
          }

          this.employeeObject = ListEvents.result[0].current_event[0].building.title;
          this.employeeStatusOnTheObject = ListEvents.result[0].current_event[0].type.title;
        } else {
          this.employeePosition = errDownload;
          this.employeeObject = errDownload;
          this.employeeStatusOnTheObject = errDownload;
        }

        this.employeeTN = StaffByColOne.result.uid;

        if (StaffByColOne.result.phone[0] === undefined) {
          this.employeePhone = errDownload;
        } else {
          this.employeePhone = StaffByColOne.result.phone[0].number;
        }

        this.employeeBlackList = !!StaffByColOne.result.black_list;
        this.employeeGrayList = !!StaffByColOne.result.grey_list;

        this.errload = '';
      }).catch((err) => {
        if (err) {
          handleCheckToken(err);
        } else {
          console.log(err.message);
        }

        this.errload = errorMessage.serverErr;
      })
      .finally(() => {
        this.loading = false;
      });
  };

  handleGetEmployeeManagerByTN = async (accessKey, TN) => {
    this.loading = true;
    this.errload = '';

    this.employeeManagerFullName = '';
    this.employeeManagerPhone = '';

    const errDownload = 'Не удалось загрузить данные';

    await getStaffByColOne(accessKey, TN)
      .then((res) => {
        if (!res.result.patronymic) {
          this.employeeManagerFullName = `${res.result.surname} ${res.resul.name}`;
        } else {
          this.employeeManagerFullName = `${res.result.surname} ${res.result.name} ${res.result.patronymic}`;
        }

        if (!res.result.phone[0].number) {
          this.employeeManagerPhone = errDownload;
        } else {
          this.employeeManagerPhone = res.result.phone[0].number;
        }

        this.errload = '';
      })
      .catch((err) => {
        console.log(err.message);
        this.errload = errorMessage.serverErr;
      })
      .finally(() => {
        this.loading = false;
      });
  };

  handleGetInitiatorDismissalByTN = async (accessKey, TN) => {
    this.loading = true;
    this.errload = '';

    this.initiatorDismissalFullName = '';
    this.initiatorDismissalPhone = '';

    const errDownload = 'Не удалось загрузить данные';

    await getStaffByColOne(accessKey, TN)
      .then((res) => {
        if (!res.result.patronymic) {
          this.initiatorDismissalFullName = `${res.result.surname} ${res.resul.name}`;
        } else {
          this.initiatorDismissalFullName = `${res.result.surname} ${res.result.name} ${res.result.patronymic}`;
        }

        if (!res.result.phone[0].number) {
          this.initiatorDismissalPhone = errDownload;
        } else {
          this.initiatorDismissalPhone = res.result.phone[0].number;
        }

        this.errload = '';
      })
      .catch((err) => {
        console.log(err.message);
        this.errload = errorMessage.serverErr;
      })
      .finally(() => {
        this.loading = false;
      });
  };

  handleGetProjectManagerByTN = async (accessKey, TN) => {
    this.loading = true;
    this.errload = '';

    this.projectManagerFullName = '';
    this.projectManagerPhone = '';
    this.projectManagerEmail = '';
    this.projectManagerID = '';

    const errDownload = 'Не удалось загрузить данные';

    await getStaffByColOne(accessKey, TN)
      .then((res) => {
        if (!res.result.patronymic) {
          this.projectManagerFullName = `${res.result.surname} ${res.resul.name}`;
        } else {
          this.projectManagerFullName = `${res.result.surname} ${res.result.name} ${res.result.patronymic}`;
        }

        if (!res.result.phone[0].number) {
          this.projectManagerPhone = errDownload;
        } else {
          this.projectManagerPhone = res.result.phone[0].number;
        }

        if (!res.result.email) {
          this.projectManagerEmail = errDownload;
        } else {
          this.projectManagerEmail = res.result.email;
        }

        this.projectManagerID = String(res.result.uid);

        this.errload = '';
      })
      .catch((err) => {
        console.log(err.message);
        this.errload = errorMessage.serverErr;
      })
      .finally(() => {
        this.loading = false;
      });
  };
}
